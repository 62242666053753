import { Button } from "@chakra-ui/react";
import React from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useHistory } from "react-router-dom";
import {
  Booking_Car_URL,
  Session_Availalbe_Car,
} from "../../../utils/constant";
import { checkPlatNomor } from "../../../utils/checkPlatNomor";
const ACTIVE_BUTTON_COLOR = "#EBF3FF";

const FilterGanjilGenap = ({ params }) => {
  const [, setAvailableCarFilter] = useLocalStorage(Session_Availalbe_Car, {});

  const btnRef = React.useRef();
  const { replace } = useHistory();
  
  const isActiveGage =
    params?.license_plate_type === "ganjil" ||
    params?.license_plate_type === "genap";
  
  return (
    <Button
      bg={isActiveGage ? ACTIVE_BUTTON_COLOR : "white"} 
      color={isActiveGage ? "#184973" : "#5C5C5C"} 
      borderColor={isActiveGage ? "#184973" : "#5C5C5C"} 
      borderWidth="1px"
      padding="6px 10px"
      borderRadius="100px"
      h="fit-content"
      letterSpacing="0.1px"
      fontSize={12}
      display="flex"
      alignItems="center"
      fontWeight={400}
      ref={btnRef}
      onClick={() => {
        const gage = checkPlatNomor(params.start_usage.split(" ")[0]);
        const filter = {
          ...params,
          license_plate_type: isActiveGage ? "" : gage,
        };
        setAvailableCarFilter(filter);
        replace({
          pathname: Booking_Car_URL,
          search: new URLSearchParams(filter).toString(),
        });
      }}
      variant={"ghost"}
    >
      Ganjil Genap: {isActiveGage ? "Ya" : "Tidak"} 
    </Button>
  );
};

export default FilterGanjilGenap;
