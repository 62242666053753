import { format } from "date-fns";
import moment from "moment/moment";
import Axios from "../utils/axiosService";
import _ from "lodash";

export const getListBookedCarDate = async ({ id }) => {
  try {
    if (!id) {
      return [];
    }
    const formatedDate = format(new Date(), "yyyy-MM-dd");

    const { data } = await Axios.get(
      `/car/booking?status=Booking Created, In Progress, Approved&finish_usage_gte=${formatedDate}&car_id=${id}&order_sequence=ASC&order_column=start_usage`
    );
    const listDate = [];
    data?.data?.data?.forEach((booked) => {
      let currentDate = moment(booked.start_usage);

      while (currentDate.isSameOrBefore(booked.finish_usage)) {
        listDate.push(moment.utc(currentDate).format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }
    });

    const uniqueDateBooked = _.uniq(listDate);

    const dateBooked = uniqueDateBooked.map((booked) => ({
      date: new Date(booked),
      message: "Date already booked",
    }));

    return dateBooked;
  } catch (error) {
    return [];
  }
};
