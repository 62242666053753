import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FetchCarDetail } from "../../../../api/fetchCarDetail";
import Axios from "../../../../utils/axiosService";

import { Flex, Button, Text, CircularProgress, useToast } from "@chakra-ui/react";

import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Session_Availalbe_Car } from "../../../../utils/constant";

import DetailCarSection from "./Section/DetailCar";
import UsersSection from "./Section/BookedCarUsers";
import RulesSection from "./Section/Rules";
import Label from "../../../Label/Label";
import CustomToast from "../../../Toast/CustomToast";

const SummaryByDate = () => {
  const { id } = useParams();
  const navigate = useHistory();
  const toast = useToast();
  const [localStorageVal] = useLocalStorage(Session_Availalbe_Car, {});

  const [car, setCar] = useState({});
  const [isLoadingCar, setIsLoadingCar] = useState(true);
  const [isLoadingSubmitBooking, setIsLoadingSubmitBooking] = useState(false);

  useEffect(() => {
    const fetchCarDetail = async () => {
      const { carDetail, loading } = await FetchCarDetail({
        id: id,
        booking_date: localStorageVal.start_usage.split(" ")[0],
      });
      setCar(carDetail);
      setIsLoadingCar(loading);
    };

    fetchCarDetail();
  }, [id, localStorageVal.start_usage]);

  const handleBookCar = () => {
    Axios.post("/car/booking", {
      car_id: parseInt(id),
      booking_status: "Booking Created",
      car_user_id: parseInt(localStorage.getItem("id_user")),
      purpose: localStorageVal.purpose,
      start_usage: localStorageVal.start_usage,
      finish_usage: localStorageVal.finish_usage,
      booking_type: localStorageVal.booking_type,
    })
      .then((res) => {
        toast({
          position: "top",
          render: () => <CustomToast title="Berhasil booking mobil" status="success" />,
          duration: 3000,
          isClosable: true,
        });
        localStorage.removeItem(Session_Availalbe_Car);
        const bookingId = res.data.data.data.id;
        navigate.push("/booking-car/booking-details/" + bookingId);
      })
      .catch((err) => {
        let errorMessage = "An error has occurred";
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.dataerrorMessage.errors.length > 0
        ) {
          errorMessage = `An error has occurred: ${err.response.data.errors[0].message}`;
        } else if (err.message) {
          errorMessage = `An error has occurred: ${err.message}`;
        }

        toast({
          position: "top",
          render: () => <CustomToast title="Error" description={errorMessage} status="error" />,
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingSubmitBooking(false);
      });
  };

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px 0px"
      flexDirection="column"
      rowGap="24px"
      height="calc(100vh - 144px)"
      overflowY="auto"
    >
      <Flex rowGap="12px" direction="column" padding="0 16px">
        <Text fontSize="14px" fontWeight="500">
          Spesifikasi Mobil
        </Text>
        {isLoadingCar ? (
          <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
            <CircularProgress isIndeterminate color="#3182CE" />
          </Flex>
        ) : (
          <Flex width="100%" direction="column" rowGap="16px">
            <DetailCarSection {...car} book_details={localStorageVal} />
          </Flex>
        )}
      </Flex>

      <Flex direction="column">
        {car.car_status === "available" ? (
          <RulesSection />
        ) : (
          <>
            <UsersSection id={id} />
            <Label text="Mobil Tidak Tersedia" />
          </>
        )}
      </Flex>
      {
        car.car_status === "available" &&
          (
              <Flex
                  position="fixed"
                  bottom="0"
                  margin="0 auto"
                  bg="white"
                  padding="16px"
                  maxWidth="500px"
                  width="100%"
                  boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
              >
                <Button
                    isLoading={isLoadingSubmitBooking}
                    bg="#184973"
                    color="white"
                    width="100%"
                    height="40px"
                    borderRadius="100px"
                    fontSize="14px"
                    fontWeight="500"
                    onClick={() => handleBookCar()}
                >
                  Book Mobil
                </Button>
              </Flex>
          )
      }
    </Flex>
  );
};

export default SummaryByDate;
