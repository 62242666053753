import React, { useRef, useState } from "react";
import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  HStack,
  NumberInput,
  NumberInputField,
  ModalHeader,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";

import CustomToast from "../../../Toast/CustomToast";

const TimePicker = ({
  isOpen,
  onClose,
  onTimeSelect,
  type,
  startUsage,
  finishUsage,
  startDate,
  finishDate,
}) => {
  const toast = useToast();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const minutesInputRef = useRef();

  const format = (value) => {
    return String(value).padStart(2, "0");
  };

  const handleSave = () => {
    const time = `${format(hours)}:${format(minutes)}`;
    const currentDate = moment();

    if (type === "start") {
      const selectedStartDateTime = moment(startDate).set({
        hour: hours,
        minute: minutes,
      });

      if (selectedStartDateTime.isBefore(currentDate)) {
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Jam tidak valid"
              description="Jam pergi tidak boleh kurang dari jam saat ini"
              status="error"
            />
          ),
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (finishDate && finishUsage) {
        const selectedFinishDateTime = moment(finishDate).set({
          hour: parseInt(finishUsage.split(":")[0]),
          minute: parseInt(finishUsage.split(":")[1]),
        });

        if (selectedStartDateTime.isAfter(selectedFinishDateTime)) {
          toast({
            position: "top",
            render: () => (
              <CustomToast
                title="Waktu tidak valid"
                description="Waktu pergi tidak boleh lebih besar dari waktu pulang"
                status="error"
              />
            ),
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
    } else if (type === "finish") {
      if (startDate && startUsage) {
        const selectedStartDateTime = moment(startDate).set({
          hour: parseInt(startUsage.split(":")[0]),
          minute: parseInt(startUsage.split(":")[1]),
        });

        const selectedFinishDateTime = moment(finishDate).set({
          hour: hours,
          minute: minutes,
        });

        if (selectedFinishDateTime.isBefore(selectedStartDateTime)) {
          toast({
            position: "top",
            render: () => (
              <CustomToast
                title="Waktu tidak valid"
                description="Waktu pulang tidak boleh kurang dari waktu pergi"
                status="error"
              />
            ),
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
    }

    onTimeSelect(time);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" background="white">
        <ModalHeader
          bg="#EBF3FF"
          textAlign="center"
          borderRadius="12px 12px 0 0"
        >
          {type === "start" ? "Jam Pergi" : "Jam Pulang"}
        </ModalHeader>
        <ModalBody padding="24px">
          <HStack spacing={4} alignItems="flex-start">
            <Flex direction="column" gap="4px">
              <NumberInput
                value={format(hours)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    minutesInputRef.current.focus();
                  }
                }}
                onChange={(valueString) => {
                  if (parseInt(valueString) > 23) {
                    setHours(23);
                  } else if (
                    (parseInt(valueString) < 0) |
                    (valueString === "")
                  ) {
                    setHours(0);
                  } else {
                    setHours(parseInt(valueString));
                  }
                }}
                max={23}
                min={0}
                bg="#EBF3FF"
                borderRadius="8px"
              >
                <NumberInputField
                  textAlign="center"
                  padding="9px 16px"
                  h="72px"
                  fontSize="45px"
                  fontWeight="400"
                />
              </NumberInput>
              <Text fontSize="14px" fontWeight="400">
                Jam
              </Text>
            </Flex>
            <Text fontSize="57px" fontWeight="400" lineHeight="72px">
              :
            </Text>
            <Flex direction="column" gap="4px">
              <NumberInput
                value={format(minutes)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSave();
                  }
                }}
                onChange={(valueString) => {
                  if (parseInt(valueString) > 59) {
                    setMinutes(59);
                  } else if (
                    (parseInt(valueString) < 0) |
                    (valueString === "")
                  ) {
                    setMinutes(0);
                  } else {
                    setMinutes(parseInt(valueString));
                  }
                }}
                max={59}
                min={0}
                bg="#EBF3FF"
                borderRadius="8px"
              >
                <NumberInputField
                  ref={minutesInputRef}
                  textAlign="center"
                  padding="9px 16px"
                  h="72px"
                  fontSize="45px"
                  fontWeight="400"
                />
              </NumberInput>
              <Text fontSize="14px" fontWeight="400">
                Menit
              </Text>
            </Flex>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} color="#34618D" colorScheme="white">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="#34618D"
            ml={3}
            colorScheme="white"
          >
            {type === "start" ? "Next" : "Ok"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TimePicker;
