import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import DateTimePicker from "./DateTimePicker";
import circleRow from "../../../assets/img/bookingCar/circle-row.svg";
import radioBlue from "../../../assets/img/bookingCar/radio-blue.svg";
import { FiPlus, FiMinus } from "react-icons/fi";
import signIcon from "../../../assets/img/bookingCar/sign-icon.svg";
import React, { useEffect, useMemo } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import OddEvenTooltipDrawer from "../MainCar/Tooltip/OddEvenTooltipDrawer";
import OddEvenTooltipModal from "../MainCar/Tooltip/OddEvenTooltipModal";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import { DeleteIcon } from "@chakra-ui/icons";
import Axios from "../../../utils/axiosService";
import { formatYYYYMMDD } from "../../../utils/formatDate";
import { useHistory } from "react-router-dom";
import DestinationItem from "./DestinationItem";
import CustomToast from "../../Toast/CustomToast";

const BookingCarDriverForm = () => {
  const {
    isOpen: isTooltipOpen,
    onOpen: onTooltipOpen,
    onClose: onTooltipClose,
  } = useDisclosure();

  const {
    isOpen: isStartTimerOpen,
    onOpen: onStartTimerOpen,
    onClose: onStartTimerClose,
  } = useDisclosure();

  const {
    isOpen: isFinishTimerOpen,
    onOpen: onFinishTimerOpen,
    onClose: onFinishTimerClose,
  } = useDisclosure();

  const isDesktop = useIsDesktop();
  const [isOddEven, setIsOddEven] = React.useState(false);

  const [startDate, setStartDate] = React.useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  });

  const [finishDate, setFinishDate] = React.useState(null);
  const [startUsage, setStartUsage] = React.useState("");
  const [finishUsage, setFinishUsage] = React.useState("");
  const [kategoriPerjalanan, setKategoriPerjalanan] = React.useState("");
  const [keperluanBooking, setKeperluanBooking] = React.useState("");
  const [jumlahPenumpang, setJumlahPenumpang] = React.useState(1);
  const [isRequestDriverClicked, setIsRequestDriverClicked] =
    React.useState(false);
  const [isDestinationListError, setIsDestinationListError] = React.useState([
    false,
  ]);
  const inputRef = React.useRef([]);
  const toast = useToast();
  const navigate = useHistory();
  const [destinations, setDestinations] = React.useState({
    location: "",
    destination_list: [
      {
        destination_name: "",
        destination_url: "",
      },
    ],
  });

  const checkError = {
    time: useMemo(
      () => startUsage === "" || finishUsage === "",
      [startUsage, finishUsage]
    ),
    location: useMemo(
      () => destinations.location === "",
      [destinations.location]
    ),
    keperluanPerjalanan: useMemo(
      () =>
        kategoriPerjalanan === "" ||
        kategoriPerjalanan === "Pilih Kategori Perjalanan",
      [kategoriPerjalanan]
    ),
    keperluanBooking: useMemo(
      () => keperluanBooking === "",
      [keperluanBooking]
    ),
    jumlahPenumpang: useMemo(() => jumlahPenumpang <= 0, [jumlahPenumpang]),
  };

  const handleDateChange = (date, type) => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    if (type === "start") {
      if (date >= tomorrow) {
        setStartDate(date);
        if (finishDate && date > finishDate) {
          setFinishDate(date);
        }
      } else {
        console.error("Start date cannot be today or earlier.");
      }
    } else {
      setFinishDate(date);
    }
  };

  const handleTimeSelect = (time, type) => {
    if (type === "start") setStartUsage(time);
    else setFinishUsage(time);
  };

  const handleLocationChange = (e) => {
    setDestinations({
      ...destinations,
      location: e.target.value,
    });
  };

  const handleDestinationChange = (e, idx) => {
    const newDestinationList = destinations.destination_list.map(
      (destination, index) => {
        if (index === idx) {
          return {
            ...destination,
            destination_name: e.target.value,
          };
        }
        return destination;
      }
    );

    setDestinations({
      ...destinations,
      destination_list: newDestinationList,
    });
  };

  const handleLinkChange = (e, idx) => {
    const newDestinationList = destinations.destination_list.map(
      (destination, index) => {
        if (index === idx) {
          return {
            ...destination,
            destination_url: e.target.value,
          };
        }
        return destination;
      }
    );

    setDestinations({
      ...destinations,
      destination_list: newDestinationList,
    });
  };

  const handleAddDestination = () => {
    setDestinations({
      ...destinations,
      destination_list: [
        ...destinations.destination_list,
        {
          destination_name: "",
          destination_url: "",
        },
      ],
    });

    setIsDestinationListError([...isDestinationListError, false]);
  };

  const handleDeleteDestinationItem = (idx) => {
    const newDestinationList = destinations.destination_list.filter(
      (destination, index) => index !== idx
    );
    setDestinations({
      ...destinations,
      destination_list: newDestinationList,
    });

    const newDestinationListError = isDestinationListError.filter(
      (error, index) => index !== idx
    );
    setIsDestinationListError(newDestinationListError);
  };

  const handleDestinationListError = (idx, value) => {
    const newDestinationListError = isDestinationListError.map(
      (error, index) => {
        if (index === idx) {
          return value;
        }
        return error;
      }
    );

    setIsDestinationListError(newDestinationListError);
  };

  const handleRequestDriver = () => {
    setIsRequestDriverClicked(true);
    const valDestinationListError = isDestinationListError.some(
      (error) => error
    );

    if (
      checkError.time ||
      checkError.location ||
      checkError.keperluanPerjalanan ||
      checkError.keperluanBooking ||
      checkError.jumlahPenumpang ||
      valDestinationListError
    ) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Tidak dapat melanjutkan"
            description="Lengkapi field yang masih salah"
            status="error"
          />
        ),
        duration: 3000,
        isClosable: true,
      });

      const firstError = Object.values(checkError).findIndex((error) => error);
      if (firstError !== -1 && firstError < 2) {
        inputRef.current[firstError].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
      if (valDestinationListError) {
        const idx = isDestinationListError.findIndex((error) => error);
        document
          .getElementById(`destination-${idx}`)
          .scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
      if (firstError !== -1) {
        inputRef.current[firstError].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }

      return;
    }
    setIsRequestDriverClicked(false);
    const destinationList = destinations.destination_list.map(
      (destination, index) => {
        return {
          order: index + 1,
          destination_name: destination.destination_name,
          destination_url: destination.destination_url,
        };
      }
    );

    Axios.post("/car/booking", {
      booking_status: "Booking Created",
      car_user_id: parseInt(localStorage.getItem("id_user")),
      purpose: keperluanBooking,
      start_usage: formatYYYYMMDD(startDate, startUsage),
      finish_usage: formatYYYYMMDD(finishDate, finishUsage),
      pickup_location: destinations.location,
      trip_category: kategoriPerjalanan,
      total_passenger: jumlahPenumpang,
      booking_type: "Driver",
      destination_list: destinationList,
      is_ganjil_genap: isOddEven,
    })
      .then((res) => {
        navigate.push(`/booking-car/booking-details/${res.data.data.data.id}`);
      })
      .catch((err) => {
        toast({
          position: "top",
          render: () => (
            <CustomToast title="Error" description={err} status="error" />
          ),
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (startDate && finishDate) {
      if (finishDate < startDate) setFinishDate(startDate);
    }
  }, [startDate, finishDate]);

  return (
    <>
      <Flex
        w="100%"
        ref={(el) => (inputRef.current[0] = el)}
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="12px"
      >
        <DateTimePicker
          type="start"
          label={"Tanggal Pergi"}
          isOpen={isStartTimerOpen}
          onOpen={onStartTimerOpen}
          onClose={onStartTimerClose}
          startUsage={startUsage}
          finishUsage={finishUsage}
          value={startUsage}
          onTimeSelect={(time) => handleTimeSelect(time, "start")}
          selectedDate={startDate}
          onDateChange={(date) => handleDateChange(date, "start")}
          startDate={startDate}
          finishDate={finishDate}
        />
        <img src={circleRow} alt="row" />
        <DateTimePicker
          type="finish"
          label={"Tanggal Pulang"}
          isOpen={isFinishTimerOpen}
          onOpen={onFinishTimerOpen}
          onClose={onFinishTimerClose}
          startUsage={startUsage}
          finishUsage={finishUsage}
          value={finishUsage}
          onTimeSelect={(time) => handleTimeSelect(time, "finish")}
          selectedDate={finishDate}
          onDateChange={(date) => handleDateChange(date, "finish")}
          startDate={startDate}
          finishDate={finishDate}
        />
      </Flex>
      <ErrorText
        condition={isRequestDriverClicked && checkError.time}
        message="Lengkapi Tanggal dan Jam"
      />
      <FormControl isRequired={true} mt="16px">
        <FormLabel fontSize="14px" fontWeight="400">
          Perjalanan
        </FormLabel>
        <InputGroup flexDirection="row" w="100%">
          <InputLeftElement>
            <img src={radioBlue} alt="radio" />
          </InputLeftElement>
          <Input
            isInvalid={isRequestDriverClicked && checkError.location}
            ref={(el) => (inputRef.current[1] = el)}
            fontSize="14px"
            fontWeight="400"
            placeholder="Lokasi Jemput"
            value={destinations.location}
            onChange={handleLocationChange}
            bg="white"
          />
          <DeleteIcon
            visibility="hidden"
            cursor="pointer"
            color="#E6001C"
            w="24px"
          />
        </InputGroup>
        <ErrorText
          condition={isRequestDriverClicked && checkError.location}
          message="Lokasi Jemput tidak boleh kosong"
        />
        {destinations.destination_list.map((destination, idx) => (
          <DestinationItem
            key={idx}
            destination={destination.destination_name}
            link={destination.destination_url}
            handleDestinationChange={handleDestinationChange}
            handleLinkChange={handleLinkChange}
            size={destinations.destination_list.length}
            handleDeleteDestinationItem={() => handleDeleteDestinationItem(idx)}
            handleDestinationListError={handleDestinationListError}
            isRequestDriverClicked={isRequestDriverClicked}
            idx={idx}
          />
        ))}
        <Button
          leftIcon={
            <Icon as={FiPlus} color="#184973" width="10.5px" height="10.5px" />
          }
          borderRadius="100"
          width="124px"
          height="25px"
          border="1px solid #184973"
          color="#184973"
          fontSize="12px"
          mt="12px"
          onClick={handleAddDestination}
          bg="transparent"
        >
          Tambah Destinasi
        </Button>
        <FormLabel fontSize="14px" fontWeight="400" mt="16px">
          Keperluan Perjalanan
        </FormLabel>
        <Select
          placeholder="Pilih Kategori Perjalanan"
          value={kategoriPerjalanan}
          ref={(el) => (inputRef.current[2] = el)}
          onChange={(e) => setKategoriPerjalanan(e.target.value)}
          isInvalid={isRequestDriverClicked && checkError.keperluanPerjalanan}
          bg="white"
          fontSize="14px"
          fontWeight="400"
        >
          <option value="Market Visit">Market Visit</option>
          <option value="Shuttle HO - Plant">Shuttle HO - Plant</option>
          <option value="External Meeting">External Meeting</option>
          <option value="Workshop/Training">Workshop/Training</option>
          <option value="Engagement">Engagement</option>
          <option value="Lainnya">Lainnya</option>
        </Select>
        <ErrorText
          condition={isRequestDriverClicked && checkError.keperluanPerjalanan}
          message="Kategori Perjalanan tidak boleh kosong"
        />
        <FormLabel fontSize="14px" fontWeight="400" mt="16px">
          Keperluan Booking
        </FormLabel>
        <Textarea
          bg="white"
          resize="none"
          placeholder="Contoh: market visit ke Pondok Indah Mall"
          ref={(el) => (inputRef.current[3] = el)}
          value={keperluanBooking}
          onChange={(e) => setKeperluanBooking(e.target.value)}
          isInvalid={isRequestDriverClicked && checkError.keperluanBooking}
          fontSize="14px"
          fontWeight="400"
          p="8px"
        />
        <ErrorText
          condition={isRequestDriverClicked && checkError.keperluanBooking}
          message="Keperluan Booking tidak boleh kosong"
        />
        <FormLabel fontSize="14px" fontWeight="400" mt="16px">
          Jumlah Penumpang
        </FormLabel>
        <InputGroup w="150px" ref={(el) => (inputRef.current[4] = el)}>
          <InputLeftElement cursor="pointer">
            <Icon
              as={FiMinus}
              fontSize="20px"
              color="#184973"
              onClick={() => {
                if (jumlahPenumpang > 0)
                  setJumlahPenumpang(jumlahPenumpang - 1);
              }}
            />
          </InputLeftElement>
          <Input
            w="150px"
            bg="white"
            value={`${jumlahPenumpang} Orang`}
            isInvalid={isRequestDriverClicked && checkError.jumlahPenumpang}
            fontSize="12px"
            fontWeight="400"
            textAlign="center"
          />
          <InputRightElement cursor="pointer">
            <Icon
              as={FiPlus}
              fontSize="20px"
              color="#184973"
              onClick={() => {
                setJumlahPenumpang(jumlahPenumpang + 1);
              }}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <ErrorText
        condition={isRequestDriverClicked && checkError.jumlahPenumpang}
        message="Jumlah Penumpang tidak boleh nol"
      />
      <FormControl display="flex" flexDirection="column" gap="8px" mt="16px">
        <Flex alignItems="center">
          <Text fontSize="14px" fontWeight="400">
            Melewati Ganjil Genap?
          </Text>
          <Icon
            as={IoMdInformationCircleOutline}
            boxSize={5}
            color="gray.500"
            cursor="pointer"
            onClick={onTooltipOpen}
            marginLeft="8px"
          />
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
          width="78px"
        >
          <FormLabel fontSize="14px" fontWeight="400" color="#5E5E5E" m="0">
            {isOddEven ? "Ya" : "Tidak"}
          </FormLabel>

          <Switch
            colorScheme="blue"
            size="md"
            isChecked={isOddEven}
            onChange={() => setIsOddEven(!isOddEven)}
          />
        </Flex>
      </FormControl>
      <Button
        w="100%"
        h="36px"
        mt="16px"
        bg="#184973"
        fontSize="14px"
        fontWeight="700"
        borderRadius="100"
        onClick={handleRequestDriver}
        color="white"
      >
        Request Driver
      </Button>
      {isDesktop ? (
        <OddEvenTooltipModal isOpen={isTooltipOpen} onClose={onTooltipClose} />
      ) : (
        <OddEvenTooltipDrawer isOpen={isTooltipOpen} onClose={onTooltipClose} />
      )}
    </>
  );
};

const ErrorText = ({ message, condition }) => {
  return (
    condition && (
      <Box w="100%" gap="8px" display="flex" flexDirection="row" mt="8px">
        <img src={signIcon} alt="sign icon" />
        <Text fontSize="12px" fontWeight="400" color="#E6001C">
          {message}
        </Text>
      </Box>
    )
  );
};

export default BookingCarDriverForm;
