import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import MascotSeeYou from "../../../assets/img/bookingCar/mascot-06-seeyou.png";
import MascotSorry from "../../../assets/img/bookingCar/mascot-sorry-colored.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function DepartureFormCompleted({ isOpen, onClose, isRoadWorthy, id }) {
  const navigate = useHistory();
  const [countdown, setCountdown] = useState(7);

  const title = isRoadWorthy ? "Selamat Jalan~" : "Mobil Tidak Layak Digunakan";
  const description = isRoadWorthy
    ? "Patuhi rambu lalu lintas dan utamakan keselamatan ya! Kami menunggu kepulanganmu"
    : "Silakan booking mobil lain atau hubungi admin untuk informasi lebih lanjut";

  useEffect(() => {
    let timer = null;
    if (isOpen) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isOpen]);

  useEffect(() => {
    if (countdown === 0) {
      if (isRoadWorthy) {
        window.location.href = "/history";
      } else {
        window.location.href = `/booking-car/booking-details/${id}`;
      }
    }
    //eslint-disable-next-line
  }, [countdown, navigate]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex
            direction="column"
            gap="24px"
            width="100%"
            padding="16px"
            bg="#FFFFFF"
            borderRadius="12px"
          >
            <Flex direction="column" gap="10px" alignItems="center">
              <Image
                src={isRoadWorthy ? MascotSeeYou : MascotSorry}
                alt=""
                w="86px"
                h="86px"
              />
              <Flex
                direction="column"
                gap="4px"
                alignItems="center"
                textAlign="center"
              >
                <Text fontSize="14px" fontWeight="500">
                  {title}
                </Text>
                <Text fontSize="14px" fontWeight="400">
                  {description}
                </Text>
              </Flex>
              <Flex
                minWidth="max-content"
                flexDirection={["column", "row"]}
                alignItems="center"
                gap={[2, 10]}
              >
                <Button
                  padding="6px 16px 6px 12px"
                  borderColor="#184973"
                  variant="outline"
                  borderRadius="100px"
                  gap="8px"
                  direction="row"
                  width="100%"
                  height="fit-content"
                  alignItems="center"
                  onClick={() =>
                    isRoadWorthy
                      ? (window.location.href = "/history")
                      : (window.location.href = `/booking-car/booking-details/${id}`)
                  }
                >
                  <Text fontSize="12px" fontWeight="400" color="#184973">
                    Tutup
                  </Text>
                  <Text fontSize="12px" fontWeight="400" color="#184973">
                    {countdown}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DepartureFormCompleted;
