import { useHistory, useLocation } from "react-router-dom";

import { Text, Flex, Box } from "@chakra-ui/react";
import {
  MdCircle,
  MdMeetingRoom,
  MdDirectionsCar,
  MdHouse,
} from "react-icons/md";

import { formatISOToDayDateTime } from "../../../../../utils/formatDate";

import LeavingReturningTime from "../../../../../components/BookingCar/Component/LeavingReturningTime";
import {
  COLOR_BLUE_PRIMARY,
  COLOR_ERROR,
  COLOR_SUCCESS,
  COLOR_WARNING,
} from "../../../../../utils/constant";

const mapBookingDetails = (booking_type, id) => {
  let icon, title, detailLink;
  switch (booking_type) {
    case "Operasional":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Operasional";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "Perjadin":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Perjalanan Dinas";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "Driver":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Driver";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    case "Room":
      icon = <MdMeetingRoom size="28px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
    case "mess":
      icon = <MdHouse size="28px" color="#222222" />;
      title = "Booking Mes";
      detailLink = `/booking-mess/booking-detail/${id}`;
      break;
    case "room":
      icon = <MdMeetingRoom size="24px" color="#222222" />;
      title = "Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
    case "car":
      icon = <MdDirectionsCar size="24px" color="#222222" />;
      title = "Car";
      detailLink = `/booking-car/booking-details/${id}`;
      break;
    default:
      icon = <MdCircle size="28px" color="#222222" />;
      title = "Booking Room";
      detailLink = `/booking-room/booking-detail/${id}`;
      break;
  }
  return { icon, title, detailLink };
};

export function Card({ type, data }) {
  const navigate = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  let status = { label: "", color: "" };

  const mappingStatus = {
    "In Progress": "Digunakan",
    Done: "Selesai",
    "Booking Cancelled": "Dibatalkan",
    Rejected: "Ditolak",
    Approved: "Disetujui",
    "Booking Created": "Diproses",
  };

  const mappingColorStatus = {
    "In Progress": COLOR_BLUE_PRIMARY,
    Done: COLOR_SUCCESS,
    "Booking Cancelled": COLOR_ERROR,
    Rejected: COLOR_ERROR,
    Approved: COLOR_SUCCESS,
    "Booking Created": COLOR_WARNING,
  };

  if (type === "history") {
    status.label = mappingStatus[data?.booking_status || "In Progress"];
    status.color = mappingColorStatus[data?.booking_status || "In Progress"];
  } else if (type === "upcoming" && data.booking_type === "Driver") {
    status.label = mappingStatus[data?.booking_status || "Booking Created"];
    status.color = mappingColorStatus[data?.booking_status || "Diproses"];
  }

  const { icon, title, detailLink } = mapBookingDetails(
    data.booking_type,
    data.booking_type?.toLowerCase() === "room" ? data.booking_id : data.id
  );

  const Divider = () => (
    <Flex px="8px" my="8px">
      <Box borderBottom="1px solid" borderColor="#EFEFEF" width="100%" />
    </Flex>
  );

  return (
    <Flex
      direction="column"
      padding="8px 0 0 0"
      mx="16px"
      my="1px"
      spacing="0"
      bg="#FFFFFF"
      style={{
        boxShadow: "0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A",
        borderRadius: "12px",
      }}
      variant="link"
      cursor="pointer"
      onClick={() =>
        navigate.push({
          pathname: detailLink,
          state: { backLink: currentPath },
        })
      }
    >
      <Flex
        direction="row"
        justifyContent="space-between"
        p="0 12px"
        alignItems="center"
      >
        <Flex direction="row" alignItems="center" columnGap="4px" w="full">
          {icon}
          <Text fontSize="12px" fontWeight="400">
            {title}
          </Text>
          {status && (
            <Box
              bg={status.color}
              borderRadius="99px"
              padding="2px 5px"
              marginLeft="auto"
            >
              <Text fontSize="9px" fontWeight="400" color="#FFFFFF">
                {status.label}
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
      <Divider />
      {data.booking_type?.toLowerCase() !== "room" && (
        <>
          <Box padding="8px 12px 12px 12px">
            <LeavingReturningTime
              leavingDate={formatISOToDayDateTime(data.start_usage).date}
              leavingTime={formatISOToDayDateTime(data.start_usage).time}
              returningDate={formatISOToDayDateTime(data.finish_usage).date}
              returningTime={formatISOToDayDateTime(data.finish_usage).time}
            />
          </Box>
        </>
      )}
      {data.booking_type?.toLowerCase() === "room" && (
        <>
          <Flex direction="row" justifyContent="space-between" padding="0 8px">
            <Flex w="50%" alignItems="flex-start" direction="column">
              <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                Jadwal
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {formatISOToDayDateTime(data.start_usage).date}
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {formatISOToDayDateTime(data.start_usage).time} -{" "}
                {formatISOToDayDateTime(data.finish_usage).time}
              </Text>
            </Flex>
            <Flex w="50%" alignItems="flex-start" direction="column">
              <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                Ruangan
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {data.booking_details.room_name},
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {data.booking_details.area_name}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex
            direction="row"
            justifyContent="space-between"
            padding="0 8px 8px 8px"
          >
            <Flex w="100%" alignItems="flex-start" direction="column">
              <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                Keperluan
              </Text>
              <Text fontSize="12px" fontWeight="400">
                {data.booking_details.booking_purpose}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
