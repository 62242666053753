import React from "react";

import Header from "../../../../components/SecondaryHeader";
import BottomNavbar from "../../../../components/Navbars/BottomNavbar";
import History from "./modules/History";

function HistoryView() {
  return (
    <>
      <Header type="all" title="Riwayat" backLink="/" />
      <History />
      <BottomNavbar />
    </>
  );
}

export default HistoryView;
