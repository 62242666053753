import {
  Flex,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Icon,
} from "@chakra-ui/react";

import { MdWhatsapp } from "react-icons/md";

import Mascot from "../../../assets/img/mascot-20.png";

function ContactModal({ isOpen, onClose }) {
  const contactNumber = "+6287709255800";

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex
            direction="column"
            gap="24px"
            width="100%"
            padding="16px"
            bg="#FFFFFF"
            borderRadius="12px"
          >
            <Flex direction="column" gap="10px" alignItems="center">
              <Image src={Mascot} alt="" w="86px" h="86px" />
              <Flex direction="column" gap="1.5rem" alignItems="center">
                <Flex
                  direction="column"
                  gap="4px"
                  alignItems="center"
                  textAlign="center"
                >
                  <Text fontSize="14px" fontWeight="500">
                    Ada Pertanyaan?
                  </Text>
                  <Text fontSize="12px" fontWeight="400">
                    Kamu bisa menghubungi Admin melalui Whatsapp
                  </Text>
                </Flex>
                <Button
                  padding="6px 16px 6px 12px"
                  color="#FFFFFF"
                  bg="#128C7E"
                  borderRadius="100px"
                  gap="8px"
                  direction="row"
                  width="100%"
                  height="32px"
                  alignItems="center"
                  onClick={() => window.open(`https://wa.me/${contactNumber}`)}
                >
                  <Icon as={MdWhatsapp} />
                  <Text fontSize="14px" fontWeight="400">
                    Hubungi Admin
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ContactModal;
