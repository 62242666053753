import formBookMess from "./views/pages/views/BookingForm/Parastay/MessBooking/index.jsx";
import formComplaint from "./views/pages/views/BookingForm/Parastay/MessComplaint/index.jsx";
import formMovinOut from "./views/pages/views/BookingForm/Parastay/MessCheckout/index.jsx";
import Login from "./views/pages/login/login.jsx";
import NotificationView from "./views/pages/views/NotificationView.jsx";
import TermsConditionsParastay from "./views/pages/views/Parastay/TermsAndConditions/index.js";
import RequestTracker from "./views/pages/views/Parastay/RequestTracker/index.jsx";
import ComplaintTrackView from "./views/pages/views/Parastay/ComplaintTrackView/index.jsx";
import BookingTrackView from "./views/pages/views/Parastay/BookingTrackView/index.jsx";
import ParastayView from "./views/pages/views/ParastayView.jsx";
import BookingRoomView from "./views/pages/views/BookingRoomView.jsx";
import BookingCarView from "./views/pages/views/BookingCar/BookingCarView.jsx";
import BookingCarDetailsView from "./views/pages/views/BookingCar/BookingCarDetailsView.jsx";
import DriverRequestView from "./views/pages/views/BookingCar/DriverRequestView.jsx";
import SummaryByCarView from "./views/pages/views/BookingCar/SummaryByCarView.jsx";
import SummaryByDateView from "./views/pages/views/BookingCar/SummaryByDateView.jsx";
import HistoryView from "./views/pages/views/history/HistoryView.jsx";
import UpcomingView from "./views/pages/views/history/UpcomingView.jsx";
import ReportView from "./views/pages/views/ReportView.jsx";
import ProfileView from "./views/pages/views/ProfileView.jsx";
import RoomDetails from "./views/pages/views/RoomDetails/index.jsx";
import BookingDetail from "./views/pages/views/BookingDetail/index.jsx";
import ContactUsView from "./views/pages/views/ContactUsView.jsx";
import TermsAndConditionsView from "./views/pages/views/TermsAndConditions/index.jsx";
import FormBookingRoom from "./views/pages/views/BookingForm/Room/index.jsx";
import AvailableCar from "./views/pages/views/BookingCar/AvailableCar.jsx";
import ListCarView from "./views/pages/views/Car/ListCarView.jsx";
import BookingFormView from "./views/pages/views/Car/BookingFormView.jsx";
import CarDepartureFormView from "./views/pages/views/BookingCar/CarDepartureFormView.jsx";
import LobbyView from "./views/pages/views/BookingCar/LobbyView";
import PerjadinView from "./views/pages/views/BookingCar/PerjadinView";
import BookingCarDriver from "./views/pages/views/BookingCar/BookingCarDriver";

const routes = [
  {
    path: "/",
    name: "Profile",
    component: ProfileView,
    layout: "/profile",
  },
  {
    path: "/",
    name: "report",
    component: ReportView,
    layout: "/report",
  },
  {
    path: "/",
    name: "Upcoming",
    component: UpcomingView,
    layout: "/upcoming",
  },
  {
    path: "/",
    name: "History",
    component: HistoryView,
    layout: "/history",
  },
  {
    path: "/",
    name: "Contact Us",
    component: ContactUsView,
    layout: "/contact-us",
  },
  {
    path: "/",
    name: "Terms and Conditions",
    component: TermsAndConditionsView,
    layout: "/terms-and-conditions",
  },
  {
    path: "/room/:roomId",
    name: "Booking Room Details",
    component: RoomDetails,
    layout: "/booking-room",
  },
  {
    path: "/booking-detail/:bookingId",
    name: "Booking Details",
    component: BookingDetail,
    layout: "/booking-room",
  },
  {
    path: "/booking/:roomId/:bookingDate",
    name: "Form Booking Room",
    component: FormBookingRoom,
    layout: "/booking-room",
  },
  {
    path: "/",
    name: "Booking Room",
    component: BookingRoomView,
    layout: "/booking-room",
  },
  {
    path: "/lobby",
    name: "Lobby",
    component: LobbyView,
    layout: "/booking-car",
  },
  {
    path: "/driver",
    name: "Booking Car Driver",
    component: BookingCarDriver,
    layout: "/booking-car",
  },
  {
    path: "/form-driver-request",
    name: "Driver Request Form",
    component: DriverRequestView,
    layout: "/booking-car",
  },
  {
    path: "/available-car",
    name: "Available Car",
    component: AvailableCar,
    layout: "/booking-car",
  },
  {
    path: "/list-car",
    name: "Booking Car",
    component: ListCarView,
    layout: "/booking-car",
  },
  {
    path: "/booking-form",
    name: "Booking Car",
    component: BookingFormView,
    layout: "/booking-car",
  },
  {
    path: "/departure/:booking_id",
    name: "Car Detail",
    component: CarDepartureFormView,
    layout: "/booking-car",
  },
  {
    path: "/summary-car/:id",
    name: "Car Detail",
    component: SummaryByCarView,
    layout: "/booking-car",
  },
  {
    path: "/summary-date/:id",
    name: "Car Detail",
    component: SummaryByDateView,
    layout: "/booking-car",
  },
  {
    path: "/booking-details/:bookingId",
    name: "Booking Car",
    component: BookingCarDetailsView,
    layout: "/booking-car",
  },
  {
    path: "/perjadin",
    name: "Perjalanan Dinas",
    component: PerjadinView,
    layout: "/booking-car",
  },
  {
    path: "/",
    name: "Booking Car",
    component: BookingCarView,
    layout: "/booking-car",
  },
  {
    path: "/form-booking-mess",
    name: "Booking Mes Form",
    component: formBookMess,
    layout: "/parastay",
  },
  {
    path: "/form-complaint",
    name: "Complaint Form",
    component: formComplaint,
    layout: "/parastay",
  },
  {
    path: "/form-moveout",
    name: "Moveout Form",
    component: formMovinOut,
    layout: "/parastay",
  },
  {
    path: "/terms-conditions",
    name: "Terms and Conditions",
    component: TermsConditionsParastay,
    layout: "/parastay",
  },
  {
    path: "/track-requests",
    name: "Lacak Request dan Komplain",
    component: RequestTracker,
    layout: "/parastay",
  },
  {
    path: "/complaint-track-view",
    name: "Request atau Komplain",
    component: ComplaintTrackView,
    layout: "/parastay",
  },
  {
    path: "/booking-track-view",
    name: "Booking Request",
    component: BookingTrackView,
    layout: "/parastay",
  },

  {
    path: "/",
    name: "Parastay",
    component: ParastayView,
    layout: "/parastay",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/",
    name: "Notification",
    component: NotificationView,
    layout: "/notification",
  },
];

export default routes;
