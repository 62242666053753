import { format } from "date-fns";
import Axios from "../utils/axiosService";

export async function FetchCarList({ params } = {}) {
  try {
    let url = `/car/list`;

    const carCategory =
      params?.car_category || params?.booking_type || "Operasional";
    params = { ...params, car_category: carCategory };

    const result = [];

    Object.keys(params).forEach((key) => {
      if (params[key] !== "") {
        switch (key) {
          case "start_usage":
            const startUsage = format(
              new Date(decodeURIComponent(params[key]).split(" ")[0]),
              "yyy-MM-dd"
            );
            result.push(`start_usage=${startUsage}`);
            break;
          case "finish_usage":
            const finishUsage = format(
                new Date(decodeURIComponent(params[key]).split(" ")[0]),
                "yyy-MM-dd"
            );
            result.push(`finish_usage=${finishUsage}`);
            break;
          case "purpose":
            break;
          default:
            if (params[key] !== "null") {
              result.push(`${key}=` + decodeURIComponent(params[key]));
            }
            break;
        }
      }
    });

    if (result.length) {
      url = url + "?" + result.join("&");
    }

    const res = await Axios.get(url);

    return {
      carList: res.data.data.data,
      loading: false,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching car list:", err);
    return {
      carList: [],
      loading: false,
      error: err,
    };
  }
}
