import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { handleSubmitDeparture } from "../../../api/submitDeparture";
import DepartureFormCompleted from "../Component/DepartureFormCompleted";
import QuestionCard from "./Card/ListQuestionCard";
import questionList from "./QuestionLists.json";
import CustomToast from "../../Toast/CustomToast";
import { useIsDesktop } from "../../../hooks/useIsDesktop";

const getDefaultValues = () => {
  const defaultValue = { damage_images: [], damage_desc: "" };
  questionList.forEach((d) => {
    defaultValue[d.name] = "";
  });
  return defaultValue;
};

const FormCarDeparture = ({ id }) => {
  const [form, setForm] = useState(() => getDefaultValues());
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [isOpenModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const questionRefs = useRef({});
  const isRoadWorthy = useRef(true);
  const isDesktop = useIsDesktop();

  const handleChangeForm = (value, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "kerusakan" &&
        value === 0 && { damage_desc: "", damage_images: [] }),
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleImageUpload = (files) => {
    setForm((prev) => ({
      ...prev,
      damage_images: [...prev.damage_images, ...Array.from(files)],
    }));
  };

  const removeImage = (index) => {
    setForm((prev) => ({
      ...prev,
      damage_images: prev.damage_images.filter((_, i) => i !== index),
    }));
  };

  const scrollToQuestion = (name) => {
    questionRefs.current[name]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
    let firstError = null;

    Object.keys(form).forEach((key) => {
      if (
        form[key] === "" &&
        key !== "damage_images" &&
        key !== "damage_desc"
      ) {
        newErrors[key] = true;
        isValid = false;
        if (!firstError) firstError = key;
      }
    });

    if (form["kerusakan"] === 1 && !form["damage_desc"]) {
      newErrors["damage_desc"] = true;
      isValid = false;
      if (!firstError) firstError = "damage_desc";
    }

    setErrors(newErrors);
    if (firstError) {
      scrollToQuestion(firstError);
    }

    return isValid;
  };

  const showToast = (title, description, status) => {
    toast({
      position: "top",
      render: ({ onClose }) => (
        <CustomToast
          title={title}
          description={description}
          status={status}
          onClose={onClose}
        />
      ),
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmitForm = async () => {
    if (!validateForm()) {
      showToast(
        "Tidak dapat melanjutkan",
        "Lengkapi pertanyaan yang masih kosong",
        "error"
      );
      return;
    }

    setIsLoading(true);

    try {
      const data = await handleSubmitDeparture({ id, payload: form });

      if (data?.error) {
        showToast("Error", "Gagal mengirim form", "error");
      } else if (data?.data?.data?.message === "Mobil Tidak Layak Digunakan") {
        setForm(getDefaultValues());
        setErrors({});
        setOpenModal(true);

        isRoadWorthy.current = false;
        setTimeout(() => {
          setOpenModal(false);
          window.location.href = `/booking-car/booking-details/${id}`;
        }, 7000);
      } else {
        showToast("Sukses", "Berhasil mengirim form", "success");

        setForm(getDefaultValues());
        setErrors({});
        setOpenModal(true);

        setTimeout(() => {
          setOpenModal(false);
          window.location.href = "/history";
        }, 7000);
      }
    } catch (error) {
      showToast("Error", "Terjadi kesalahan saat mengirim form", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      bg="#F8FBFF"
      padding="16px 0px"
      direction="column"
      width="100%"
      rowGap="24px"
      height="calc(100vh - 144px)"
      overflowY="auto"
    >
      <Box padding="0 24px">
        <Text fontSize="14px" fontWeight="500">
          Lengkapi form berikut ini sebelum menggunakan mobil
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="16px">
          Isi form sesuai dengan keadaan mobil sesungguhnya sebagai bentuk
          kepedulian kita terhadap keselamatan
        </Text>

        <VStack width="100%" spacing="16px" align="stretch">
          {questionList.map((question, index) => (
            <div
              key={question.name}
              ref={(el) => (questionRefs.current[question.name] = el)}
            >
              <QuestionCard
                index={index}
                form={form}
                handleChangeForm={handleChangeForm}
                handleImageUpload={handleImageUpload}
                removeImage={removeImage}
                isError={errors[question.name]}
                isDamageDescError={errors["damage_desc"]}
                {...question}
              />
            </div>
          ))}
        </VStack>
      </Box>

      <Flex
        position="fixed"
        bottom="0"
        margin="0 auto"
        bg="white"
        padding="16px"
        maxWidth="500px"
        width="100%"
        boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
      >
        <Button
          bg="#184973"
          isLoading={isLoading}
          color="white"
          onClick={handleSubmitForm}
          width="100%"
          height="40px"
          borderRadius="100px"
          fontSize="14px"
          fontWeight="500"
          _hover={{ bgColor: !isDesktop ? "#184973" : "#6382A1" }}
        >
          Kirim {isLoading && <Spinner size="sm" ml={2} />}
        </Button>
      </Flex>

      <DepartureFormCompleted
        isOpen={isOpenModal}
        isRoadWorthy={isRoadWorthy.current}
        id={id}
        onClose={() => {
          setOpenModal(false);
          window.location.href = isRoadWorthy.current
            ? "/history"
            : `/booking-car/booking-details/${id}`;
        }}
      />
    </Flex>
  );
};

export default FormCarDeparture;
