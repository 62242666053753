import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';

const ModalRounded = ({ isOpen, onClose, title, body, footer }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent 
        bg="white"
        maxHeight="90vh"
        borderRadius="16px" 
      >
        <ModalHeader 
          fontSize="16px"
          color="#222222"
          pb="8px"
        >
          {title}
        </ModalHeader>

        <ModalCloseButton borderRadius="10px"/>
        
        <ModalBody mb={footer ? "0px" : "16px"} >
          {body}
        </ModalBody>

        {footer && (
          <ModalFooter
            borderTop="1px solid"
            borderColor="gray.200"
          >
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalRounded;