import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import FormDate from "./FormDate";

function ChangeDate() {
  const btnRef = React.useRef();

  const isDesktop = useIsDesktop();

  const {
    isOpen: isOpenChangeDate,
    onOpen: onOpenChangeDate,
    onClose: onCloseChangeDate,
  } = useDisclosure();

  return (
    <>
      <Button 
        colorScheme="blue" 
        variant="ghost" 
        onClick={onOpenChangeDate}
        bg="#EBF3FF"
        color="#184973"
        padding="4px 10px"
        borderRadius="100px"
        w="72px"
        h="25px"
        letterSpacing="0.1px"
        fontSize={12}
        gap="8px"
        display="flex"
        alignItems="center"
        fontWeight={400}
        ref={btnRef}
      >
        Edit Form
      </Button>
      {isDesktop ? (
        <Modal isOpen={isOpenChangeDate} onClose={onCloseChangeDate} isCentered>
          <ModalOverlay />
          <ModalContent 
            color="black" 
            zIndex={99} 
            bg="white" 
            borderRadius="16px" 
          >
            <ModalHeader
              p="12px 16px 0 16px"
              fontSize="16px"
              fontWeight="700"
            >
              Formulir Booking
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody padding="0px">
              <FormDate onClose={onCloseChangeDate} />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          isOpen={isOpenChangeDate}
          placement="bottom"
          onClose={onCloseChangeDate}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent 
            bg="white"
            borderTopRadius="32px"
            maxHeight="90vh"
          >
            <Box 
              width="32px" 
              height="4px" 
              bg="gray.600" 
              borderRadius="100px" 
              margin="16px auto"
            />
            <DrawerHeader
              p="0 16px"
              fontSize="16px"
              fontWeight="700"
              lineHeight="22.4px"
            >
              Formulir Booking
            </DrawerHeader>

            <DrawerBody padding="0px">
              <FormDate onClose={onCloseChangeDate} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default ChangeDate;
