import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderAvailableCar from "../../../../components/BookingCar/AvailableCar/HeaderAvailableCar";
import ListCar from "../../../../components/BookingCar/AvailableCar/ListCar";
import FilterGanjilGenap from "../../../../components/BookingCar/Component/FilterGanjilGenap";
import FilterSelectCapacity from "../../../../components/BookingCar/Component/FilterSelectCapacity";
import { useGetQueryParams } from "../../../../hooks/useGetQueryParams";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { Session_Availalbe_Car } from "../../../../utils/constant";

const AvailableCar = () => {
  const { params, size, search } = useGetQueryParams();
  const navigate = useHistory();
  const { pathname } = useLocation();
  const [localStorageVal, setLocalStorageStateValue] = useLocalStorage(Session_Availalbe_Car, null);
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    setIsRender(true);
  }, []);

  useEffect(() => {
    if (isRender) {
      if (!localStorageVal) {
        if (!!search) {
          setLocalStorageStateValue(params);
        } else {
          navigate.goBack();
        }
      }
    }
  }, [isRender, localStorageVal, params, navigate, search, setLocalStorageStateValue, size]);

  useEffect(() => {
    if (size <= 0 && localStorageVal) {
      navigate.replace({
        pathname,
        search: new URLSearchParams(localStorageVal).toString(),
      });
    }
  }, [localStorageVal, pathname, navigate, size]);

  return (
    <Flex gap="24px" flexDir="column" bg="#F8FBFF" minH="100vh">
      <Box boxShadow="0px 0px 3px 0px #0000001A">
        <HeaderAvailableCar
          bookingType={params?.booking_type || null}
          startUsage={params?.start_usage || null}
          finishUsage={params?.finish_usage || null}
        />
        <Flex bg="white" padding="8px 16px 8px 16px" gap={"12px"}>
          <FilterSelectCapacity params={params} />
          <FilterGanjilGenap params={params} />
        </Flex>
      </Box>

      <ListCar params={params} search={search} />
    </Flex>
  );
};

export default AvailableCar;
