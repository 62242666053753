import React from 'react';
import { Box, Text, VStack, HStack } from '@chakra-ui/react';

const UnworthinessDetail = ({ facilityLog }) => {
  const departureLog = facilityLog?.find(log => log.log_status === "depart");
  
  const facilities = [
    { key: 'kaca_spion', name: 'Kaca Spion' },
    { key: 'klakson', name: 'Klakson' },
    { key: 'lampu', name: 'Lampu Besar, Lampu Rem, dan Lampu Sein' },
    { key: 'rem', name: 'Rem Tangan dan Rem Kaki' },
    { key: 'dashboard', name: 'Indikator Dashboard Pengemudi' }
  ];

  const missingFacilities = facilities.filter(
    facility => departureLog && !departureLog[facility.key]
  );

  if (!missingFacilities.length) return null;

  return (
    <Box>
      <Text 
        fontSize="12px" 
        fontWeight="500" 
        color="#5C5C5C"
        lineHeight="16.8px"
      >
        Alasan mobil tidak layak digunakan
      </Text>
      
      <VStack align="stretch" mt={2} mb={3} spacing={0}>
        {missingFacilities.map((facility, index) => (
          <HStack key={facility.key} spacing={1}>
            <Text fontSize="12px" color="#222222">
              {index + 1}.{" "}
            </Text>
            <Text fontSize="12px" color="#222222">
              {` ${facility.name}: `}
            </Text>
            <Text 
              fontSize="12px" 
              color="#E6001C" 
              fontWeight="500"
            >
              Tidak Ada
            </Text>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default UnworthinessDetail;
