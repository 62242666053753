import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FetchCarDetail } from "../../../../api/fetchCarDetail";

import { Flex, Button, Text, CircularProgress } from "@chakra-ui/react";

import DetailCarSection from "./Section/DetailCar";
import UsersSection from "./Section/BookedCarUsers";
import RulesSection from "./Section/Rules";
import Label from "../../../Label/Label";

const SummaryByCar = () => {
  const { id } = useParams();
  const navigate = useHistory();

  const [car, setCar] = useState({});
  const [isLoadingCar, setIsLoadingCar] = useState(true);

  useEffect(() => {
    const fetchCarDetail = async () => {
      const { carDetail, loading } = await FetchCarDetail({ id: id });
      setCar(carDetail);
      setIsLoadingCar(loading);
    };

    localStorage.setItem("car_id", id);
    fetchCarDetail();
  }, [id]);

  const isCarAvailable = car.car_status === "available";

  return (
    <Flex
      bg="#F8FBFF"
      padding="24px 0px"
      flexDirection="column"
      rowGap="24px"
      height="calc(100vh - 144px)"
      overflowY="auto"
    >
      <Flex rowGap="12px" direction="column" padding="0 16px">
        <Text fontSize="14px" fontWeight="500">
          Spesifikasi Mobil
        </Text>
        {isLoadingCar ? (
          <Flex
            width="100%"
            height="300px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress isIndeterminate color="#3182CE" />
          </Flex>
        ) : (
          <Flex width="100%" direction="column" rowGap="16px">
            <DetailCarSection {...car} />
          </Flex>
        )}
      </Flex>

      <UsersSection id={id} />

      {isCarAvailable && (
        <>
          <RulesSection />

          <Flex
            position="fixed"
            bottom="0"
            margin="0 auto"
            bg="white"
            padding="16px"
            maxWidth="500px"
            width="100%"
            boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
          >
            <Button
              isLoading={isLoadingCar}
              bg="#184973"
              color="white"
              width="100%"
              height="40px"
              borderRadius="100px"
              fontSize="14px"
              fontWeight="500"
              onClick={() =>
                navigate.push(`/booking-car/booking-form?id=${id}`)
              }
            >
              Isi Formulir Booking
            </Button>
          </Flex>
        </>
      )}

      {!isCarAvailable && <Label text="Mobil Tidak Tersedia" />}
    </Flex>
  );
};

export default SummaryByCar;
