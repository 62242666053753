import React from 'react';
import ModalRounded from '../../../Modal/ModalRounded';
import OddEvenTooltip from './OddEvenTooltip';

const OddEvenTooltipModal = ({ isOpen, onClose }) => {
  const { title, content } = OddEvenTooltip();

  return (
    <ModalRounded
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={content}
    />
  );
};

export default OddEvenTooltipModal;