import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";

import { MdLocationOn } from "react-icons/md";

const CarLocation = ({ current_location, location_url }) => {
  return (
    <Flex gap="4px" direction="column">
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Text fontSize="12px" fontWeight="500" color="#5E5E5E">
          Lokasi Pengambilan
        </Text>
        <Flex gap="2px">
          <MdLocationOn size={16} color="#E6001C" />
          <Button
            color="#3182CE"
            fontSize="12px"
            fontWeight="400"
            background="transparent"
            variant="link"
            width="fit-content"
            onClick={() => window.open(location_url, "_blank")}
          >
            Lihat lokasi
          </Button>
        </Flex>
      </Flex>
      <Text fontSize="12px" fontWeight="400" color="#222222">
        {" "}
        {current_location}
      </Text>
    </Flex>
  );
};

export default CarLocation;
