import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Label = ({ text, backgroundColor = "#EFEFEF", textColor = "#5C5C5C" }) => {
  if (!text) return null;

  return (
    <Box 
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      backgroundColor={backgroundColor}
      padding="10px 16px"
      width="100%"
      maxWidth="500px"
      margin="0 auto"
    >
      <Text 
        fontSize="16px" 
        fontWeight="700" 
        padding="6px 16px"
        color={textColor}
        textAlign="center"
      >
        {text}
      </Text>
    </Box>
  );
};

export default Label;