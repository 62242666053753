import {
  Flex,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import MascotQuestion from "../../assets/img/mascot-03.png";

function ContactListModal({ data, isOpen, onClose }) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex
            direction="column"
            gap="24px"
            width="100%"
            padding="16px"
            bg="#FFFFFF"
            borderRadius="12px"
          >
            <Flex direction="column" gap="10px" alignItems="center">
              <Image src={MascotQuestion} alt="" w="86px" h="86px" />
              <Flex
                direction="column"
                gap="4px"
                alignItems="center"
                textAlign="center"
              >
                <Text fontSize="14px" fontWeight="500">
                  Hubungi Admin
                </Text>
                <Text fontSize="12px" fontWeight="400">
                  Pilih Admin yang ingin kamu hubungi melalui Whatsapp
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction="column"
              gap="16px"
              justifyContent="center"
              alignItems="center"
            >
              {Object.values(data).map((contact, index) => (
                <Button
                  key={index}
                  padding="6px 12px"
                  borderRadius="100px"
                  bg="#FFFFFF"
                  width="130px"
                  height="32px"
                  color="#128C7E"
                  border="1px solid #128C7E"
                  onClick={() =>
                    window.open(`https://wa.me/${contact.contact_number}`)
                  }
                >
                  <Text fontSize="14px" fontWeight="500" textAlign="center">
                    {contact.contact_name}
                  </Text>
                </Button>
              ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ContactListModal;
