import React from "react";

import { useParams } from "react-router";
import FormCarDeparture from "../../../../components/BookingCar/MainCar/FormCarDeparture";
import Header from "../../../../components/SecondaryHeader";

function CarDepartureFormView() {
  const params = useParams();

  return (
    <>
      <Header
        title="Form Kelengkapan Kendaraan"
        isPreviousPage={true}
        backLink={true}
        type="departure"
      />
      <FormCarDeparture id={params["booking_id"]} />
    </>
  );
}

export default CarDepartureFormView;
