import React from 'react';
import {Flex} from "@chakra-ui/react";

const PenjelasanFiturContent = () => {
    return (
        <Flex
            w="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            bg="white"
            gap="16px">
            <Flex
                w="100%"
                direction="column"
                alignItems="start"
                justifyContent="center"
                bg="white"
                gap="4px">
                <p style={{fontSize:"16px", fontWeight:"400"}}>Operasional</p>
                <p style={{fontSize:"12px"}}>
                    Booking mobil untuk perjalanan <strong>dalam Jabodetabek tidak menginap</strong>. Seperti untuk keperluan market visit, external meeting, workshop, training, engagement, dan lainnya.
                </p>
            </Flex>
            <Flex
                w="100%"
                direction="column"
                alignItems="start"
                justifyContent="center"
                bg="white"
                gap="4px">
                <p style={{fontSize:"16px", fontWeight:"400"}}>Perjalanan Dinas</p>
                <p style={{fontSize:"12px"}}>
                    Booking mobil untuk perjalanan <strong>luar Jabodetabek atau perjalanan menginap</strong>. Seperti perjalanan dinas ke luar kota.
                </p>
            </Flex>
            <Flex
                w="100%"
                direction="column"
                alignItems="start"
                justifyContent="center"
                bg="white"
                gap="4px">
                <p style={{fontSize:"16px", fontWeight:"400"}}>Driver</p>
                <p style={{fontSize:"12px"}}>
                    Booking Driver untuk <strong>menjemput dan mengantar ke lokasi pilihan kamu</strong>. Tidak perlu booking mobil secara terpisah karena pada booking driver sudah termasuk mobil yang akan digunakan.
                </p>
            </Flex>
        </Flex>
    )
}

export default PenjelasanFiturContent;