import { Flex, Box, Text } from "@chakra-ui/react";

import {
  MdPendingActions,
  MdOutlineAccessTime,
  MdOutlineLocalGasStation,
  MdCreditCard,
} from "react-icons/md";

const ruleItems = [
  {
    icon: <MdPendingActions size="16px" />,
    text: "Pengguna harus melakukan pengecekan keadaan mobil sebelum menggunakan mobil.",
  },
  {
    icon: <MdOutlineAccessTime size="16px" />,
    text: "Penggunaan mobil maksimal hingga pukul 12 malam di hari yang sama.",
  },
  {
    icon: <MdOutlineLocalGasStation size="16px" />,
    text: "Bensin harus dalam keadaan full (100%) saat mengembalikan mobil.",
  },
  {
    icon: <MdCreditCard size="16px" />,
    text: "Pengguna diharapkan membawa alat transaksi elektronik seperti e-money atau semacamnya.",
  },
];

const RulesSection = () => {
  return (
    <Flex rowGap="12px" direction="column" padding="0 16px" marginBottom="32px">
      <Text fontSize="14px" fontWeight="500">
        Peraturan Penggunaan
      </Text>

      <Flex
        width="100%"
        direction="column"
        rowGap="16px"
        borderRadius="12px"
        p="12px"
        bg="white"
        color="#222222"
        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
      >
        {ruleItems.map((item, index) => (
          <Flex key={index} align="center">
            <Box
              color="blue.500"
              mr="12px"
              borderRadius="full"
              bg="#EBF3FF"
              alignContent="center"
              padding="6px"
            >
              {item.icon}
            </Box>
            <Text fontSize="13px" fontWeight="400">
              {item.text}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default RulesSection;
