import React from "react";
import { Text, VStack, Link, Box } from "@chakra-ui/react";

const OddEvenTooltip = () => {
  return {
    title: "Daerah Ganjil Genap",
    content: (
      <VStack align="start" spacing={4}>
        <Box fontSize="14px" color="#5C5C5C" mb="0px">
          <Text>
            Ganjil Genap di Jakarta berlaku pada hari kerja (Senin-Jumat) dan
            tidak berlaku pada hari libur nasional atau hari Sabtu dan Minggu.
            Ganjil Genap berlaku dalam dua sesi waktu,
          </Text>
          <Text> yaitu Pukul 06.00-10.00 WIB dan Pukul 16.00-21.00 WIB.</Text>
          <Text mt="8px">
            Lihat website{" "}
            <Link
              color="blue.500"
              href="https://jakarta.go.id/ganjil-genap"
              isExternal
              fontStyle="italic"
              fontWeight="700"
            >
              jakarta.go.id/ganjil-genap
            </Link>{" "}
            untuk mengetahui wilayah yang menerapkan Ganjil Genap di Jakarta
          </Text>
        </Box>
      </VStack>
    ),
  };
};

export default OddEvenTooltip;
