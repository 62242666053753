import React from "react";

import styles from "./baselayout.module.css";

const BaseLayout = ({ children }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>{children}</div>
    </div>
  );
};

export default BaseLayout;
