import React from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../../components/SecondaryHeader";
import SummaryByCarSection from "../../../../components/BookingCar/MainCar/Summary/SummaryByCar";

function SummaryByCarView() {
  const location = useLocation();
  const { backLink } = location.state || { backLink: "/" };

  return (
    <>
      <Header
        type="car"
        title="Detail Mobil"
        backLink={backLink}
        isPreviousPage={true}
      />
      <SummaryByCarSection />
    </>
  );
}

export default SummaryByCarView;
