import { Box, Flex, Spinner, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FetchCarList } from "../../api/fetchCarList";
import ListCarWithLabel from "../Shared/Booking-Car/ListCarWithLabel";
import NoCarsAvailablePlaceholder from "../Shared/Booking-Car/NoCarsAvailablePlaceholder";
import CustomToast from "../Toast/CustomToast";

const getListCar = ({ listCar = [] }) => {
  const available = [];
  const noAvailable = [];
  const lastUsed = [];

  listCar.forEach((car) => {
    if (car.is_last_used) {
      lastUsed.push(car);
    } else {
      if (car.car_status === "available") {
        available.push(car);
      } else {
        noAvailable.push(car);
      }
    }
  });

  return {
    available,
    noAvailable,
    lastUsed,
  };
};

const ListCar = ({ params, bookingType }) => {
  const [car, setCar] = useState({
    available: [],
    noAvailable: [],
    lastUsed: [],
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const setListAvailableCar = async () => {
      setLoading(true);
      try {
        const updatedParams = { ...params, car_category: bookingType };

        const response = await FetchCarList({ params: updatedParams });
        if (response?.error === null) {
          const { available, noAvailable, lastUsed } = getListCar({
            listCar: response.carList,
          });

          setCar({ available, noAvailable, lastUsed });
        }
        setLoading(response.loading);
      } catch (error) {
        toast({
          position: "top",
          render: () => <CustomToast title="Gagal mengambil list mobil" status="error" />,
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    setListAvailableCar();
  }, [bookingType, params, toast]);

  return (
    <Box paddingX="16px" pb="24px">
      {loading ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          {car.lastUsed.length > 0 && (
            <ListCarWithLabel
              label="Terakhir Digunakan"
              loading={loading}
              cars={car.lastUsed}
              pageType="byCar"
            />
          )}
          {car.available.length >= 0 && (
            <ListCarWithLabel
              label="Tersedia"
              loading={loading}
              cars={car.available}
              emptyPlaceholder={NoCarsAvailablePlaceholder}
              pageType="byCar"
            />
          )}
          {car.noAvailable.length > 0 && (
            <ListCarWithLabel
              label="Tidak Tersedia"
              loading={loading}
              cars={car.noAvailable}
              pageType="byCar"
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ListCar;
