import React, {useEffect, useMemo} from "react";
import isUrl from "../../../utils/checkUrl";
import {Box, Button, Flex, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Text} from "@chakra-ui/react";
import {FiX} from "react-icons/fi";
import linkIcon from "../../../assets/img/bookingCar/link-icon.svg";
import {DeleteIcon} from "@chakra-ui/icons";
import signIcon from "../../../assets/img/bookingCar/sign-icon.svg";


const DestinationItem = ({
                             idx,
                             destination,
                             link,
                             handleDestinationChange,
                             handleLinkChange,
                             handleDeleteDestinationItem,
                             handleDestinationListError,
                             isRequestDriverClicked,
                             size}) => {

    const errorCondtion = {
        destination: useMemo(() => destination === "", [destination]),
        link: useMemo(() => link === "" || !isUrl(link), [link]),
    }

    const errorTextLink = useMemo(() => {
        if (link=== "") return "Link Google Maps wajib diisi"
        if (!isUrl(link)) return "Link tidak sesuai format"
        return ""
    },[link])

    useEffect(()=>{
        handleDestinationListError(idx, errorCondtion.destination || errorCondtion.link)
        // eslint-disable-next-line
    },[errorCondtion.destination, errorCondtion.link])

    const [onFocus, setOnFocus] = React.useState(false)
    const [pasteOnClicked, setPasteOnClicked] = React.useState(false)

    return (
        <Flex
            w="100%"
            id={`destination-${idx}`}
            direction="column">
            <Box
                w="100%"
                borderLeft="1px dashed black"
                h="18px"
                marginLeft="18px"/>
            <Flex
                w="100%"
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap="8px">
                <Box
                    w="100%"
                    borderRadius="8px"
                    border={
                        isRequestDriverClicked && (errorCondtion.destination || errorCondtion.link)
                            ? "1px solid #E6001C" : "1px solid #EBEBEB"
                    }
                    bg="white"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <InputGroup>
                        <InputLeftElement>
                            <Box
                                borderRadius="100"
                                bg="#EBF3FF"
                                fontSize="10px"
                                fontWeight="500"
                                w="16px"
                                h="16px"
                                textAlign="center"
                                pt="1px">
                                {idx+1}
                            </Box>
                        </InputLeftElement>
                        <Input
                            fontSize="14px"
                            fontWeight="400"
                            value={destination}
                            onChange={(e)=>handleDestinationChange(e, idx)}
                            placeholder="Destinasi"
                            borderRadius="8px 8px 0px 0px"
                            borderBottom="1px solid white"/>
                        <InputRightElement cursor="pointer" >
                            <Icon as={FiX} fontSize="16px" onClick={()=>handleDestinationChange({target:{value: ""}}, idx)}/>
                        </InputRightElement>
                    </InputGroup>
                    <Box w="90%" border="0.75px solid #EBEBEB"/>
                    <InputGroup>
                        <InputLeftElement>
                            <Box
                                borderRadius="100"
                                bg="#EBF3FF"
                                fontSize="10px"
                                fontWeight="500"
                                w="16px"
                                h="16px"
                                textAlign="center"
                                pt="1px">
                                <img src={linkIcon} alt="link icon"/>
                            </Box>
                        </InputLeftElement>
                        <Input
                            fontSize="14px"
                            fontWeight="400"
                            value={link}
                            onChange={(e)=>handleLinkChange(e, idx)}
                            onFocus={()=>setOnFocus(true)}
                            onBlur={()=>{
                                setTimeout(() => {
                                    setOnFocus(pasteOnClicked || false);
                                }, 100);
                            }}
                            color={isUrl(link)?"#3182CE":"black"}
                            textDecoration={isUrl(link)?"underline":"none"}
                            placeholder="Link Google Maps"
                            borderRadius="0px 0px 8px 8px"
                            borderTop="1px solid white"/>
                        <InputRightElement>
                            <Box
                                mr="48px"
                                gap="8px"
                                display="flex"
                                flexDirection="row">
                                <Button
                                    bg="white"
                                    borderRadius="100"
                                    border="1px solid #184973"
                                    fontSize="8px"
                                    fontWeight="500"
                                    color="#184973"
                                    visibility={(link==="" && onFocus)?"visible":"hidden"}
                                    onClick={()=>{
                                        setPasteOnClicked(true)
                                        navigator.clipboard.readText().then(text => {
                                            handleLinkChange({target:{value:text}}, idx)
                                        }).finally(()=>setPasteOnClicked(false))
                                    }}
                                    w="16px"
                                    h="16px">
                                    Paste
                                </Button>
                                <Icon as={FiX} fontSize="16px" onClick={()=>handleLinkChange({target: {value: ""}}, idx)} cursor="pointer"/>
                            </Box>
                        </InputRightElement>
                    </InputGroup>
                </Box>
                <DeleteIcon
                    visibility={size<=1?"hidden":"visible"}
                    cursor="pointer"
                    color="#E6001C"
                    onClick={handleDeleteDestinationItem}/>
            </Flex>
            <ErrorText
                message="Destinasi tidak boleh kosong"
                condition={isRequestDriverClicked && errorCondtion.destination}/>
            <ErrorText
                message={errorTextLink}
                condition={isRequestDriverClicked && errorCondtion.link}/>
        </Flex>
    )
}

const ErrorText = ({message, condition}) => {
    return (
        condition && (
            <Box w="100%" gap="8px" display="flex" flexDirection="row" mt="8px">
                <img src={signIcon} alt="sign icon"/>
                <Text fontSize="12px" fontWeight="400" color="#E6001C">{message}</Text>
            </Box>
        )
    )
}


export default DestinationItem;