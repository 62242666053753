import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import ChangeDate from "./ChangeDate";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import id from "date-fns/locale/id";

const HeaderAvailableCar = ({ bookingType, startUsage, finishUsage }) => {
  const navigate = useHistory();

  return (
    <Flex
      padding="16px"
      alignItems="center"
      gap={4}
      bg="white"
      borderBottom="0.5px"
      borderColor="dbdbdb"
    >
      <Box
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (bookingType === "Operasional") {
            navigate.push("/booking-car");
          } else {
            navigate.push("/booking-car/perjadin");
          }
        }}
      >
        <BsChevronLeft />
      </Box>
      <Box>
        <Text fontWeight={500} fontSize={14}>
          Cari Mobil
        </Text>
        <Flex gap={2} fontSize="12px">
          {bookingType === "Operasional" ? (
            <>
              <Text>
                {startUsage
                  ? format(
                      new Date(startUsage.split(" ")[0]),
                      "dd MMMM yyyy ",
                      { locale: id }
                    )
                  : "-"}
              </Text>
              <Text>|</Text>
              <Text>{startUsage ? startUsage.split(" ")[1] : "-"}</Text>
              <Text>-</Text>
              <Text>{finishUsage ? finishUsage.split(" ")[1] : "-"}</Text>
            </>
          ) : (
            // bookingType === "Perjadin"
            <>
              <Text>
                {startUsage
                  ? format(new Date(startUsage.split(" ")[0]), "dd MMM yyyy ", {
                      locale: id,
                    })
                  : "-"}
              </Text>
              <Text>-</Text>
              <Text>
                {finishUsage
                  ? format(
                      new Date(finishUsage.split(" ")[0]),
                      "dd MMM yyyy ",
                      { locale: id }
                    )
                  : "-"}
              </Text>
            </>
          )}
        </Flex>
      </Box>
      <Box marginLeft="auto">
        <ChangeDate />
      </Box>
    </Flex>
  );
};

export default HeaderAvailableCar;
