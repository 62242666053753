import { useState } from "react";
import { Image, Box, Flex, Text, Icon, Button } from "@chakra-ui/react";
import MascotCTA from "../../assets/img/mascot-08.png";
import { MdWhatsapp } from "react-icons/md";

import ContactListModal from "./ContactListModal";
import { CONTACT_LIST_DATA } from "../../utils/constant";

function Contact({ type = "all" }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box width="100%" padding="0 16px">
      <Flex
        direction="row"
        gap="10px"
        width="100%"
        padding="12px 8px"
        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
        borderRadius="12px"
        bg="#FFFFFF"
        justifyContent="space-between"
      >
        <Flex direction="column" gap="10px">
          <Flex direction="column" gap="4px">
            <Text fontSize="14px" fontWeight="500">
              Butuh bantuan?
            </Text>
            <Text fontSize="12px" fontWeight="400">
              Admin siap untuk membantu kamu loh!
            </Text>
          </Flex>
          <Button
            padding="6px 16px 6px 12px"
            color="#184973"
            borderRadius="100px"
            border="1px solid #184973"
            gap="8px"
            direction="row"
            width="fit-content"
            height="32px"
            bg="#FFFFFF"
            alignItems="center"
            onClick={() =>
              type === "all"
                ? setIsOpen(true)
                : window.open(
                    `https://wa.me/${CONTACT_LIST_DATA[type].contact_number}`
                  )
            }
          >
            <Icon as={MdWhatsapp} />
            <Text fontSize="14px" fontWeight="400">
              Hubungi Admin
            </Text>
          </Button>
        </Flex>
        <Box w="86px" h="86px">
          <Image src={MascotCTA} alt="" />
        </Box>
      </Flex>

      {type === "all" && (
        <ContactListModal
          data={CONTACT_LIST_DATA}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Box>
  );
}

export default Contact;
