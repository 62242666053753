import { Flex } from "@chakra-ui/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import CarAttributes from "../../Component/CarAttributes";

const CarCard = ({
  id,
  name,
  brand,
  capacity,
  car_status,
  fuel,
  image,
  last_service,
  license_plate,
  pageType, // byCar or byDate
}) => {
  const navigate = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Flex
      borderRadius="12px"
      p="12px"
      bg=" white"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
      cursor="pointer"
      onClick={() =>
        navigate.push({
          pathname:
            pageType === "byCar"
              ? `/booking-car/summary-car/${id}`
              : `/booking-car/summary-date/${id}`,
          state: { backLink: currentPath, id: id },
        })
      }
    >
      <CarAttributes
        brand={brand}
        name={name}
        car_status={car_status}
        license_plate={license_plate}
        capacity={capacity}
        fuel={fuel}
        last_service={last_service}
        image={image}
        showCarStatus={true}
        showCarTransmisionColor={false}
      />
    </Flex>
  );
};

export default CarCard;
