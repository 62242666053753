import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  HStack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { MdAccessTime, MdOutlineDateRange } from "react-icons/md";

import { useLocation } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import id from "date-fns/locale/id";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "../MainCar/Modal/TimePicker";
import CustomToast from "../../Toast/CustomToast";

const DateTimePicker = ({
  label,
  value,
  selectedDate,
  onDateChange,
  isOpen,
  onOpen,
  onClose,
  onTimeSelect,
  type,
  startUsage,
  finishUsage,
  excludeDate,
  startDate,
  finishDate,
}) => {
  const toast = useToast();

  const location = useLocation();

  const minDate =
    location.pathname === "/booking-car/driver"
      ? new Date(new Date().setDate(new Date().getDate() + 2))
      : new Date();

  const formatDate = (date) => {
    return format(date, "EEEE, dd MMMM yyyy", { locale: id });
  };

  const showToast = (title, description) => {
    toast({
      position: "top",
      render: () => <CustomToast title={title} description={description} status="error" />,
      duration: 5000,
      isClosable: true,
    });
  };

  const handleDateChange = (date) => {
    const currentDate = moment().startOf("day");
    const selectedMoment = moment(date).startOf("day");

    if (selectedMoment.isBefore(currentDate)) {
      showToast("Tanggal tidak valid", "Tanggal yang dipilih tidak boleh kurang dari hari ini");
      return;
    }

    if (type === "start" && finishDate && moment(date).isAfter(finishDate)) {
      showToast("Tanggal tidak valid", "Tanggal pergi tidak boleh lebih besar dari tanggal pulang");
      return;
    }

    if (type === "finish" && startDate && moment(date).isBefore(startDate)) {
      showToast("Tanggal tidak valid", "Tanggal pulang tidak boleh lebih kecil dari tanggal pergi");
      return;
    }

    onDateChange(date);
  };

  const handleTimeSelect = (time) => {
    const selectedDateTime = moment(selectedDate).set({
      hour: parseInt(time.split(":")[0]),
      minute: parseInt(time.split(":")[1]),
    });

    const currentDateTime = moment();
    const otherDateTime =
      type === "start"
        ? finishDate &&
          finishUsage &&
          moment(finishDate).set({
            hour: parseInt(finishUsage?.split(":")[0] || 0),
            minute: parseInt(finishUsage?.split(":")[1] || 0),
          })
        : startDate &&
          startUsage &&
          moment(startDate).set({
            hour: parseInt(startUsage?.split(":")[0] || 0),
            minute: parseInt(startUsage?.split(":")[1] || 0),
          });

    if (selectedDateTime.isBefore(currentDateTime)) {
      showToast("Waktu tidak valid", "Waktu yang dipilih tidak boleh kurang dari waktu saat ini");
      return;
    }

    if (otherDateTime) {
      if (type === "start" && selectedDateTime.isAfter(otherDateTime)) {
        showToast("Waktu tidak valid", "Waktu pergi tidak boleh lebih besar dari waktu pulang");
        return;
      }

      if (type === "finish" && selectedDateTime.isBefore(otherDateTime)) {
        showToast("Waktu tidak valid", "Waktu pulang tidak boleh lebih kecil dari waktu pergi");
        return;
      }
    }

    onTimeSelect(time);
  };

  return (
    <VStack spacing={4} w="50%">
      <FormControl zIndex={9}>
        <FormLabel fontSize="14px" fontWeight="500" textAlign="center" w="100%">
          {label}
        </FormLabel>
        <InputGroup
          bg="white"
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={
            type === "start"
              ? {
                  ".react-datepicker-wrapper": {
                    width: "100%",
                  },
                  ".react-datepicker__tab-loop": {
                    zIndex: 9,
                  },
                  ".react-datepicker-popper": {
                    transform: "translate(0%, 134px) !important",
                  },
                }
              : {
                  ".react-datepicker-wrapper": {
                    width: "100%",
                  },
                  ".react-datepicker__tab-loop": {
                    zIndex: 9,
                  },
                  ".react-datepicker-popper": {
                    right: "0",
                    left: "unset !important",
                    transform: "translate(0%, 134px) !important",
                  },
                }
          }
        >
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="eeee, dd MMMM yyyy"
            excludeDates={excludeDate}
            customInput={
              <Input
                fontSize="14px"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                border="none"
                py="0"
                h="124px"
                value={selectedDate ? formatDate(selectedDate) : ""}
                sx={{
                  color: "transparent",
                  caretColor: "transparent",
                }}
              />
            }
            minDate={minDate}
            maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
            locale="id"
            zIndex={9}
          />
          <Box position="absolute" top="8px" zIndex={10} pointerEvents="none">
            <Text fontSize="14px" fontWeight="400" color={selectedDate ? "black" : "#7A7A7A"}>
              {selectedDate ? format(selectedDate, "EEEE", { locale: id }) : "Pilih hari"}
            </Text>
            <Text fontSize="48px" fontWeight="400" color={selectedDate ? "black" : "#7A7A7A"}>
              {selectedDate ? format(selectedDate, "dd", { locale: id }) : "--"}
            </Text>
            <HStack>
              {selectedDate && <Icon as={MdOutlineDateRange} color="#222222" />}
              <Text fontSize="12px">
                {selectedDate ? format(selectedDate, "MMMM yyyy", { locale: id }) : ""}
              </Text>
            </HStack>
          </Box>
        </InputGroup>
      </FormControl>

      <FormControl isRequired>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={MdAccessTime} color="#222222" />}
          />
          <Input
            bg="white"
            borderRadius="8px"
            padding="8px"
            value={value}
            onClick={onOpen}
            isReadOnly
            cursor="pointer"
            paddingLeft="32px"
            placeholder="Pilih Jam"
            fontSize="14px"
          />
        </InputGroup>
        <TimePicker
          isOpen={isOpen}
          onClose={onClose}
          onTimeSelect={handleTimeSelect}
          type={type}
          startUsage={startUsage}
          finishUsage={finishUsage}
          startDate={startDate}
          finishDate={finishDate}
        />
      </FormControl>
    </VStack>
  );
};

export default DateTimePicker;
