import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import React from "react";
import LeavingReturningTime from "../../../Component/LeavingReturningTime";
import { formatISOtoDayAndHHmm } from "../../../../../utils/formatDate";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdRadioButtonChecked, MdWhatsapp } from "react-icons/md";
import Divider from "../Divider";

const DetailDriver = ({
  image,
  driverStatus,
  detailStatus,
  bookingDetails,
  bg,
  contactNumber,
  isShow,
}) => {
  if (!isShow) {
    return null;
  }
  return (
    <>
      <Flex
        direction="column"
        height="fit-content"
        paddingBottom="48px"
        bg={bg}
        alignItems="center"
      >
        <Flex w="64px" h="64px" margin="24px">
          <Image src={image} alt="" />
        </Flex>
        <Flex direction="column" gap="4px" margin="0 16px" alignItems="center">
          <Text fontSize="14px" fontWeight="700">
            {driverStatus}
          </Text>
          <Text fontSize="14px" fontWeight="400" textAlign="center">
            {detailStatus}
          </Text>
          {bookingDetails.booking_status === "Rejected" && (
            <Button
              padding="6px 16px 6px 12px"
              color="#128C7E"
              bg="#FFFFFF"
              border="1px solid #128C7E"
              borderRadius="100px"
              gap="8px"
              direction="row"
              width="100%"
              height="32px"
              alignItems="center"
              onClick={() => window.open(`https://wa.me/${contactNumber}`)}
              mt="20px"
            >
              <Icon as={MdWhatsapp} />
              <Text fontSize="14px" fontWeight="400">
                Hubungi Admin
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
      <Box minHeight="300px">
        <Flex padding="0 16px" position="relative" top="-20px">
          <Accordion allowMultiple width="full" border="1px solid #EBEBEB" borderRadius="30px">
            <AccordionItem border="none">
              <h2>
                <AccordionButton
                  bg="white"
                  border="1px solid #EBEBEB"
                  borderRadius="999px"
                  _expanded={{ bg: "white" }}
                  _focus={{ bg: "white" }}
                  _hover={{ bg: "white" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Informasi Perjalanan
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex width="100%" direction="column" rowGap="8px" mt="16px">
                  <LeavingReturningTime
                    leavingDate={formatISOtoDayAndHHmm(bookingDetails.start_usage).date}
                    leavingTime={formatISOtoDayAndHHmm(bookingDetails.start_usage).time}
                    returningDate={formatISOtoDayAndHHmm(bookingDetails.finish_usage).date}
                    returningTime={formatISOtoDayAndHHmm(bookingDetails.finish_usage).time}
                  />

                  <Divider />
                  {(bookingDetails.booking_status === "Approved" ||
                    (bookingDetails.car !== null && bookingDetails.driver !== null)) && (
                    <>
                      <Flex width="100%" direction="row" rowGap="8px" fontSize="12px">
                        <Flex width="100%" direction="column" rowGap="8px">
                          <Text fontSize="12px" fontWeight="500">
                            Driver
                          </Text>
                          <Flex direction="row">
                            {bookingDetails.driver?.driver_image ? (
                              <Image
                                src={bookingDetails.driver.driver_image}
                                alt="driver"
                                w="64px"
                                h="fit-content"
                                my="auto"
                                maxH="100px"
                                borderRadius="8px"
                              />
                            ) : (
                              <Flex
                                w="64px"
                                h="64px"
                                bg="#F8F8F8"
                                borderRadius="8px"
                                my="auto"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              ></Flex>
                            )}
                            <Flex direction="column" ml="12px">
                              <Text
                                overflow="hidden"
                                textOverflow="ellipsis"
                                width="228px"
                                height="34px"
                                display="-webkit-box"
                                maxWidth="16em"
                              >
                                {bookingDetails.driver?.driver_name}
                              </Text>
                              <Button
                                mt="6px"
                                padding="6px 16px 6px 12px"
                                border="1px solid #128C7E"
                                color="#128C7E"
                                bg="#FFFFFF"
                                borderRadius="100px"
                                gap="4px"
                                direction="row"
                                width="105px"
                                height="24px"
                                alignItems="center"
                                onClick={() =>
                                  window.open(
                                    `https://wa.me/${bookingDetails.driver.driver_contact}`
                                  )
                                }
                              >
                                <Icon as={MdWhatsapp} />
                                <Text fontSize="10px" fontWeight="400">
                                  Hubungi Driver
                                </Text>
                              </Button>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider />
                      <Flex width="100%" direction="row" rowGap="8px" fontSize="12px">
                        <Flex width="100%" direction="column" rowGap="8px">
                          <Text fontSize="12px" fontWeight="500">
                            Kendaraan
                          </Text>
                          <Flex direction="row">
                            {bookingDetails.car?.image ? (
                              <Image
                                src={bookingDetails.car?.image}
                                alt="car"
                                w="64px"
                                h="fit-content"
                                my="auto"
                                maxH="100px"
                                borderRadius="8px"
                              />
                            ) : (
                              <Flex
                                w="64px"
                                h="64px"
                                bg="#F8F8F8"
                                borderRadius="8px"
                                my="auto"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              ></Flex>
                            )}
                            <Flex direction="column" ml="12px" gap="9px" justifyContent="center">
                              <Flex fontFamily="14px" fontWeight="700" alignItems="center">
                                {bookingDetails.car?.brand} {bookingDetails.car?.name}
                              </Flex>
                              <Flex
                                color="#222222"
                                fontSize="13px"
                                fontWeight="400"
                                direction="row"
                                alignItems="center"
                                gap="0.5rem"
                              >
                                <Flex
                                  fontSize="9px"
                                  bg="#222222"
                                  color="white"
                                  p="2px 4px"
                                  borderRadius="2px"
                                >
                                  PLAT
                                </Flex>
                                <Flex>{bookingDetails.car?.license_plate}</Flex>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Flex fontSize="10px" fontWeight="400" fontStyle="italic" color="#5C5C5C">
                            *Kendaraan dapat berubah sewaktu-waktu.
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider />
                    </>
                  )}

                  <Flex width="100%" direction="column" rowGap="8px">
                    <Text fontSize="12px" fontWeight="500">
                      Lokasi Jemput
                    </Text>
                    <Flex width="100%" direction="row" alignItems="center" gap="8px">
                      <MdRadioButtonChecked color="#389FFF" />
                      <Text fontSize="12px">{bookingDetails.pickup_location}</Text>
                    </Flex>
                  </Flex>

                  <Divider />
                  <Flex width="100%" direction="column" rowGap="8px">
                    <Text fontSize="12px" fontWeight="500">
                      Destinasi
                    </Text>
                    <Flex
                      direction="column"
                      gap="12px"
                      divider={<StackDivider borderColor="gray.200" />}
                    >
                      {bookingDetails?.destination_list?.map((destination, index) => (
                        <Flex direction="row" key={index} alignItems="start" spacing={3}>
                          <Flex
                            bg="#EBF3FF"
                            color="black"
                            borderRadius="50%"
                            w="24px"
                            h="24px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            fontSize="12px"
                            mr={3}
                          >
                            {index + 1}
                          </Flex>

                          <Flex direction="column">
                            <Text fontSize="12px" fontWeight="400" mb="8px">
                              {destination.destination_name}
                            </Text>

                            <Button
                              as="a"
                              href={destination.destination_url}
                              target="_blank"
                              leftIcon={<Icon as={FaMapMarkerAlt} />}
                              width="93px"
                              height="21px"
                              color="red"
                              variant="ghost"
                              fontSize="12px"
                              borderRadius="9999px"
                              padding="2px 6px 2px 4px"
                              boxShadow="0px 0px 2px 0px #0000001A"
                            >
                              <Text color="#3182CE" fontWeight="400">
                                Lihat Lokasi
                              </Text>
                            </Button>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                  <Divider />
                  <Flex direction="row" justifyContent="space-between">
                    <Flex w="50%" alignItems="flex-start" direction="column">
                      <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                        Kategori Perjalanan
                      </Text>
                      <Text fontSize="12px" fontWeight="400">
                        {bookingDetails.trip_category}
                      </Text>
                    </Flex>
                    <Flex w="50%" alignItems="flex-start" direction="column">
                      <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
                        Jumlah Penumpang
                      </Text>
                      <Text fontSize="12px" fontWeight="400">
                        {bookingDetails.total_passenger}
                      </Text>
                    </Flex>
                  </Flex>
                  <Divider />
                  <Flex direction="column" pb="12px" gap="4px">
                    <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
                      Keperluan Booking
                    </Text>
                    <Box
                      width="100%"
                      height="fit-content"
                      padding="8px 12px"
                      gap="10px"
                      borderRadius="8px"
                      bg="#F5F5F5"
                    >
                      <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
                        {bookingDetails.purpose}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Box>
    </>
  );
};

export default DetailDriver;
