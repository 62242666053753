import Axios from "../utils/axiosService";

export const handleSubmitDeparture = async ({ payload, id }) => {
  const formData = new FormData();
  formData.append("kaca_spion", payload.kaca_spion === 1 ? "true" : "false");
  formData.append("klakson", payload.klakson === 1 ? "true" : "false");
  formData.append("lampu", payload.lampu === 1 ? "true" : "false");
  formData.append("rem", payload.rem === 1 ? "true" : "false");
  formData.append("dashboard", payload.dashboard === 1 ? "true" : "false");
  formData.append("kunci_dongkrak", payload.kunci_dongkrak === 1 ? "true" : "false");
  formData.append("apar", payload.apar === 1 ? "true" : "false");
  formData.append("payung", payload.payung === 1 ? "true" : "false");
  formData.append("segitiga_pengaman", payload.segitiga_pengaman === 1 ? "true" : "false");
  formData.append("dongkrak_ban", payload.dongkrak_ban === 1 ? "true" : "false");
  formData.append("kerusakan", payload.kerusakan === 1 ? "true" : "false");

  if (!!payload.damage_desc) {
    formData.append("damage_desc", payload.damage_desc);
  }

  if (!!payload.damage_images) {
    payload.damage_images.forEach(file => {
      formData.append("damage_image", file)
    });
  }

  try {
    const { data } = await Axios.post(`/car/departure/${id}`, formData, { 
      headers: { 
        'Content-Type': 'multipart/form-data' 
      } 
    });

    return { data, error: false };
  } catch (error) {
    return {
      data: error.response?.data || null,
      error: true,
    };
  }
};
