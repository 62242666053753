import React, { useState } from "react";
import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { BiQuestionMark } from "react-icons/bi";
import { MdOutlineChevronLeft } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { CONTACT_LIST_DATA } from "../utils/constant.js";
import CarContactModal from "./BookingCar/Header/ContactModal.jsx";
import ContactListModal from "./CTA/ContactListModal.jsx";
import FormModal from "./BookingCar/Header/FormModal.jsx";

function Header({ 
  title, 
  backLink,
  type, 
  isPreviousPage = false,
  isSticky = true,  // Header will be sticky by default (adjustable)
  zIndex = 1000     // Adjustable zIndex
}) {
  const navigate = useHistory();
  const [isOpenQuestion, setIsOpenQuestion] = useState(false);

  return (
    <Box
      position={isSticky ? "sticky" : "relative"}
      top={0}
      zIndex={zIndex}
      width="100%"
      bg="#FFFFFF"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding="16px"
      >
        <Flex alignItems="center" justifyContent="left" gap="16px">
          <Box
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
            borderRadius="100"
            width="28px"
            height="28px"
          >
            {(backLink || isPreviousPage) && (
              <IconButton
                icon={<MdOutlineChevronLeft color="#222222" size="20px" style={{marginRight:"12px"}}/>}
                onClick={() => {
                  if (isPreviousPage) {
                    navigate.goBack();
                  } else {
                    navigate.push(backLink);
                  }
                }}
                colorScheme="white"
                width="24px"
                height="24px"
              />
            )}
          </Box>
          <Text fontSize="16px" fontWeight="400" color="#222222">
            {title}
          </Text>
        </Flex>
        {type && ( 
          <Button
            bg="#FFFFFF"
            height="24px"
            width="24px"
            minWidth="24px"
            borderRadius="100%"
            padding="4px"
            cursor="pointer"
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
            onClick={() => setIsOpenQuestion(true)}
          >
            <BiQuestionMark color="#222222" size="16px" />
          </Button>
        )}
      </Flex>

      {isOpenQuestion && (
        <>
          {type === "car" ? (
            <CarContactModal isOpen={isOpenQuestion} onClose={() => setIsOpenQuestion(false)} />
          ) : type === "departure" ? (
            <FormModal isOpen={isOpenQuestion} onClose={() => setIsOpenQuestion(false)} />
          ) : type === "all" ? (
            <ContactListModal
              data={CONTACT_LIST_DATA}
              isOpen={isOpenQuestion}
              onClose={() => setIsOpenQuestion(false)}
            />) : {}
          }
        </>
      )}
    </Box>
  );
}

export default Header;
