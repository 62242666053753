import { Flex, Box, Image, Button, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ThanksMascot from "../../../assets/img/bookingCar/mascot-19-thanks.png";
import { MdWhatsapp } from "react-icons/md";

const Registered = () => {
  const navigate = useHistory();
  const contactNumber = "+6287709255800";
  return (
    <Box width="100%" borderBottom="1px solid #EFEFEF">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="16px"
        padding="48px 32px"
      >
        <Image src={ThanksMascot} alt="Thanks" width="120px" height="120px" />
        <Flex direction="column" gap="4px">
          <Text
            fontSize="14px"
            fontWeight="500"
            color="#222222"
            textAlign="center"
          >
            Terima kasih sudah mendaftar Booking Mobil
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#5E5E5E"
            textAlign="center"
          >
            Admin akan mengecek data kamu. Mohon menunggu.
          </Text>
        </Flex>
        <Button
          height="32px"
          padding="6px 16px 6px 12px"
          color="#184973"
          borderRadius="100px"
          border="1px solid #184973"
          gap="8px"
          direction="row"
          width="fit-content"
          bg="#FFFFFF"
          alignItems="center"
          onClick={() => window.open(`https://wa.me/${contactNumber}`)}
        >
          <MdWhatsapp />
          <Text fontSize="14px" fontWeight="400">
            Hubungi Admin
          </Text>
        </Button>
        <Button
          color="#184973"
          fontSize="14px"
          fontWeight="400"
          background="transparent"
          variant="link"
          onClick={() => navigate.push("/booking-car/form-driver-request")}
        >
          Lihat data yang didaftarkan
        </Button>
      </Flex>
    </Box>
  );
};

export default Registered;
