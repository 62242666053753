import React, { useState } from "react";
import Axios from "../../../../../utils/axiosService";
import { useParams, useHistory } from "react-router-dom";
import { ACTIVE_BUTTON_COLOR } from "../../../../../utils/constant";

import {
  Flex,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useToast,
} from "@chakra-ui/react";

import Mascot from "../../../../../assets/img/mascot-20.png";
import CustomToast from "../../../../Toast/CustomToast";

function CancelModal({ isOpen, onClose }) {
  const { bookingId } = useParams();
  const toast = useToast();
  const navigate = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const handleCancelBooking = () => {
    setIsLoading(true);

    Axios.post("/car/booking/" + bookingId + "/cancel")
      .then((res) => {
        toast({
          position: "top",
          render: () => <CustomToast title="Berhasil melakukan Cancel Booking" status="success" />,
          duration: 3000,
          isClosable: true,
        });
        onClose();
        navigate.push("/history");
      })
      .catch((err) => {
        let errorMessage = "An error has occurred";
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.length > 0
        ) {
          errorMessage = `An error has occurred: ${err.response.data.errors[0].message}`;
        } else if (err.message) {
          errorMessage = `An error has occurred: ${err.message}`;
        }

        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Gagal melakukan Cancel Booking"
              description={errorMessage}
              status="error"
            />
          ),
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered w="100%">
      <ModalOverlay w="100%" h="100%" />
      <ModalContent borderRadius="12px" w="80%" padding="24px">
        <ModalBody padding="0">
          <Flex
            direction="column"
            gap="24px"
            width="100%"
            padding="16px"
            bg="#FFFFFF"
            borderRadius="12px"
          >
            <Flex direction="column" gap="16px" alignItems="center">
              <Image src={Mascot} alt="" w="86px" h="86px" />
              <Flex direction="column" gap="4px" alignItems="center" textAlign="center">
                <Text fontSize="14px" fontWeight="500">
                  Cancel Booking
                </Text>
                <Text fontSize="12px" fontWeight="400">
                  Book yang sudah dicancel tidak dapat dikembalikan. Lanjutkan?
                </Text>
              </Flex>
              <Flex gap="16px" width="100%" justifyContent="center">
                <Button
                  fontSize="14px"
                  fontWeight="400"
                  padding="6px 12px"
                  color={ACTIVE_BUTTON_COLOR}
                  bg="white"
                  borderRadius="100px"
                  gap="8px"
                  direction="row"
                  width="114px"
                  height="32px"
                  alignItems="center"
                  onClick={onClose}
                  border="1px"
                  borderColor={ACTIVE_BUTTON_COLOR}
                  letterSpacing="0.1px"
                >
                  Kembali
                </Button>
                <Button
                  fontSize="14px"
                  fontWeight="400"
                  padding="6px 12px"
                  color="white"
                  bgColor={ACTIVE_BUTTON_COLOR}
                  borderRadius="100px"
                  border="1px"
                  borderColor={ACTIVE_BUTTON_COLOR}
                  gap="8px"
                  direction="row"
                  width="114px"
                  height="32px"
                  alignItems="center"
                  isLoading={isLoading}
                  onClick={handleCancelBooking}
                >
                  Lanjutkan
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CancelModal;
