import React from 'react';
import DrawerRounded from '../../../Drawer/DrawerRounded';
import OddEvenTooltip from './OddEvenTooltip';

const OddEvenTooltipDrawer = ({ isOpen, onClose }) => {
  const { title, content } = OddEvenTooltip();

  return (
    <DrawerRounded
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={content}
    />
  );
};

export default OddEvenTooltipDrawer;