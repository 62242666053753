import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { MdOutlineChevronLeft } from "react-icons/md";
import { getAlphabetByIndex } from "../../../../utils/getAlphabetByIndex";
import { useState } from "react";
import Axios from "../../../../utils/axiosService";

const SyaratKetentuan = ({
  isOpen,
  onClose,
  isForm,
  handleSubmit,
  driverRequest,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const termsAndCondition = [
    {
      text: "Biaya bensin dan tol selama perjalanan menjadi tanggung jawab user menggunakan Panjer masing-masing",
    },
    {
      text: "Segala kerusakan yang terjadi pada mobil selama masa peminjaman menjadi tanggung jawab driver/user",
    },
    {
      text: "Jika perjalanan yang mengharuskan menginap, biaya penginapan driver menjadi tanggung jawab user",
    },
    {
      text: "Mobil harus dikembalikan dalam keadaan:",
      subText: [
        {
          text: "Bensin terisi penuh",
        },
        {
          text: "Kondisi bagian dalam kedaaan bersih (tidak ada sampah yg tertinggal)",
        },
      ],
    },
  ];

  const confirmConsent = async () => {
    setIsLoading(true);
    const user_id = localStorage.getItem("id_user");
    const user_data = JSON.parse(localStorage.getItem("user_data"));

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("username", user_data.user_email);
    formData.append("is_consent", true);
    formData.append("active", true);

    try {
      await Axios({
        method: "PATCH",
        url: `/driver/request/${driverRequest.id}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleSubmit();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isMobile ? "full" : "lg"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid #EBEBEB">
          <Flex marginBottom={isForm ? "0px" : "16px"} alignItems="center">
            {isMobile ? (
              <Flex
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                borderRadius="100"
                width="28px"
                height="28px"
                justifyContent="flex-start"
                alignItems="center"
                marginRight="16px"
              >
                <IconButton
                  icon={
                    <MdOutlineChevronLeft
                      color="#222222"
                      size="20px"
                      style={{ marginRight: "12px" }}
                    />
                  }
                  onClick={onClose}
                  colorScheme="white"
                  width="24px"
                  height="24px"
                />
              </Flex>
            ) : (
              <ModalCloseButton />
            )}
            {isForm && (
              <Text fontSize="16px">Syarat dan Ketentuan Booking Car</Text>
            )}
          </Flex>
          {!isForm && (
            <Text
              fontSize="14px"
              color="#222222"
              fontWeight="400"
              maxWidth={isMobile ? "100%" : "90%"}
            >
              Sebelum melanjutkan, pastikan kamu setuju terhadap Syarat dan
              Ketentuan Booking Car
            </Text>
          )}
        </ModalHeader>
        <ModalBody background="#F8FBFF" borderRadius="8px">
          {!isForm && <Text>Syarat dan Ketentuan Booking Car</Text>}
          {termsAndCondition.map((terms, index) => (
            <Flex id={`terms-${index}`} color="#5C5C5C" marginBottom="16px">
              <Text width="16px" fontSize="12px">
                {index + 1}.
              </Text>
              <Text fontSize="12px" width="calc(100% - 24px)">
                {terms.text}
                {terms.subText?.map((subTerms, subIndex) => (
                  <Flex id={`subTerms-${subIndex}`}>
                    <Text width="16px" fontSize="12px">
                      {getAlphabetByIndex(subIndex)}.
                    </Text>
                    <Text fontSize="12px" width="calc(100% - 24px)">
                      {subTerms.text}
                    </Text>
                  </Flex>
                ))}
              </Text>
            </Flex>
          ))}
        </ModalBody>
        {!isForm && (
          <ModalFooter flexDirection="column">
            <Checkbox
              marginRight="8px"
              isChecked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              alignItems="flex-start"
            >
              <Text fontSize="12px" color="#222222">
                Saya telah{" "}
                <strong>membaca, memahami, dan bersedia mengikuti</strong>{" "}
                Syarat dan Ketentuan Booking Car yang diterapkan oleh HRCA.
              </Text>
            </Checkbox>
            <Button
              marginTop="12px"
              width="100%"
              background="#184973"
              color="white"
              borderRadius="999px"
              py="6px"
              isDisabled={!isChecked}
              fontSize="14px"
              onClick={confirmConsent}
              isLoading={isLoading}
            >
              Kirim
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SyaratKetentuan;
