import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";
const HeaderBookingForm = () => {
  const navigate = useHistory();
  return (
    <Flex
      padding="16px"
      alignItems="center"
      gap={4}
      bg="white"
      borderBottom="0.5px"
      borderColor="#dbdbdb"
      sha
    >
      <Box style={{ cursor: "pointer" }} onClick={() => navigate.goBack()}>
        <BsChevronLeft />
      </Box>
      <Heading fontSize="14px" fontWeight="400">
        Booking Mobil
      </Heading>
    </Flex>
  );
};

export default HeaderBookingForm;
