import React, { useRef } from "react";
import {
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { CiCirclePlus } from "react-icons/ci";
import { CloseIcon } from "@chakra-ui/icons";

const QuestionCard = ({
  title,
  subtitle,
  subtitle2,
  additional,
  option,
  index,
  name,
  form,
  handleChangeForm,
  handleImageUpload,
  removeImage,
  isError,
  isDamageDescError,
}) => {
  const refInput = useRef(null);

  const borderColor = isError ? "#E6001C" : "gray.200";

  const handleFileChange = (event) => {
    const files = event.target.files;
    handleImageUpload(files);
  };

  return (
    <Flex
      borderRadius="12px"
      borderColor={borderColor}
      borderWidth={isError ? 1 : 0.5}
      p="12px"
      bg="white"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
      flexDirection="column"
    >
      <Flex gap="2px" flexDir="column">
        <Flex ml="12px">
          <Text fontSize="12px" fontWeight="400">
            {index + 1}.
          </Text>
          <Text fontSize="12px" fontWeight="400" ml="10px">
            {title}
          </Text>
        </Flex>
        <Flex ml="32px" flexDir="column" gap="2px">
          <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
            {subtitle}
          </Text>
          <Text fontSize="12px" fontWeight="400" color="#5E5E5E">
            {subtitle2}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            color="#5E5E5E"
            fontStyle="italic"
          >
            {additional}
          </Text>

          <RadioGroup
            name={name}
            onChange={(e) => {
              handleChangeForm(Number(e), name);
            }}
            value={form[name]}
          >
            <Stack direction="row">
              {option.map((opt) => (
                <Radio value={opt.value} key={opt.value}>
                  <Text fontSize="12px">{opt.name}</Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Flex>
      </Flex>

      {name === "kerusakan" && form[name] === 1 && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt="10px"
          pl="32px"
          pr="12px"
          width="100%"
        >
          <Text w="full" textAlign="start" fontSize="12px">
            Jelaskan kerusakan yang terjadi <span style={{ color: "red" }}>*</span>
          </Text>
          <Textarea
            fontSize="12px"
            p="8px"
            value={form["damage_desc"]}
            onChange={(e) => {
              handleChangeForm(e.target.value, "damage_desc");
            }}
            borderColor={isDamageDescError ? "#E6001C" : "gray.200"}
            borderWidth={isDamageDescError ? 1 : 0.5}
          />
          <Text mt="8px" w="full" textAlign="start" fontSize="12px">
            Masukkan foto bukti kerusakan mobil (opsional)
          </Text>
          <input
            ref={refInput}
            style={{ display: "none" }}
            onChange={handleFileChange}
            type="file"
            accept="image/*"
            multiple
          />
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            w="full"
            p="30px 10px"
            borderWidth="1px"
            borderRadius="lg"
            cursor="pointer"
            borderStyle="dashed"
            borderColor="#73777F"
            gap="5px"
            onClick={() => refInput.current.click()}
          >
            <Button 
              leftIcon={<CiCirclePlus size="20px" />} 
              color="#215AA8" 
              borderColor="#215AA8"
              variant='outline' 
              fontSize="14px" 
            >
              Tambah Foto
            </Button>
            <Text fontSize="14px" color="#5E5E5E">
              Maksimal 5MB
            </Text>
          </Flex>
          {form.damage_images.length > 0 && (
            <Flex flexDirection="column" w="full" mt="15px">
              {form.damage_images.map((file, index) => (
                <Flex
                  key={index}
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                  p="10px"
                  borderBottomWidth="1px"
                  borderColor="#E2E8F0"
                >
                  <Flex alignItems="center" flexWrap="wrap">
                    <Box
                      width="40px"
                      height="40px"
                      borderRadius="4px"
                      overflow="hidden"
                      mr="10px"
                    >
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded file ${index + 1}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </Box>
                    <Text 
                      fontSize="12px" 
                      isTruncated
                      style={{
                        textOverflow: 'ellipsis',
                        maxWidth: '180px',
                        overflow:"hidden"
                      }}
                      maxWidth={{ base: "120px", sm: "280px", md: "400px" }}
                    >
                      {file.name}
                    </Text>
                  </Flex>
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    variant="ghost"
                    onClick={() => removeImage(index)}
                    aria-label="Remove image"
                  />
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default QuestionCard;
