import React, {useEffect} from "react";
import {
    Box, Button,
    Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay,
    Flex, Modal,
    ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import LobbyHeader from "../../../../components/BookingCar/Header/LobbyHeader";
import mascot from "../../../../assets/img/mascot-05.png";
import cardLobby1 from "../../../../assets/img/bookingCar/card-lobby-01.svg";
import cardLobby2 from "../../../../assets/img/bookingCar/card-lobby-02.svg";
import cardLobby3 from "../../../../assets/img/bookingCar/card-lobby-03.svg";
import onBoarding1 from "../../../../assets/img/bookingCar/onboarding-01.svg";
import onBoarding2 from "../../../../assets/img/bookingCar/onboarding-02.svg";
import onBoarding3 from "../../../../assets/img/bookingCar/onboarding-03.svg";
import onBoarding4 from "../../../../assets/img/bookingCar/onboarding-04.svg";
import {useHistory} from "react-router-dom";
import CardLobby from "../../../../components/BookingCar/Component/CardLobby";
import {useIsDesktop} from "../../../../hooks/useIsDesktop";
import PenjelasanFiturContent from "../../../../components/BookingCar/Component/PenjelasanFiturContent";


function LobbyView() {
    const navigate = useHistory();
    const btnRef = React.useRef();
    const [activeIndexOnBoarding, setActiveIndexOnBoarding] = React.useState(0);
    const {
        isOpen: isOpenPenjelasanFitur,
        onOpen: onOpenPenjelasanFitur,
        onClose: onClosePenjelasanFitur,
    } = useDisclosure();

    const {
        isOpen: isOpenOnBoarding,
        onOpen: onOpenOnBoarding,
        onClose: onCloseOnBoarding,
    } = useDisclosure();

    const handleNextOnBoarding = (isSkip) => {
        if (isSkip || activeIndexOnBoarding === onBoardingItems.length-1) {
            onCloseOnBoarding();
            localStorage.setItem("hadOnBoarding", "true");
            return;
        }

        if (activeIndexOnBoarding < onBoardingItems.length-1)
            setActiveIndexOnBoarding(activeIndexOnBoarding + 1);
    }

    useEffect(() => {
        if (!localStorage.getItem("hadOnBoarding")) {
            onOpenOnBoarding();
        }
        localStorage.removeItem("bookingFormFilterData");
        // eslint-disable-next-line
    }, [])

    const isDesktop = useIsDesktop();
    const onBoardingItems = [
        {
            title: "Selamat Datang \ndi Paraspace Booking Car",
            body: "Melalui Paraspace Booking Car kamu dapat memesan Mobil dan Driver untuk menunjang kebutuhan kamu.",
            image: onBoarding1
        },
        {
            title: "Operasional",
            body: "Booking mobil untuk perjalanan dalam Jabodetabek tanpa menginap.",
            image: onBoarding2
        },
        {
            title: "Perjalanan Dinas",
            body: "Booking mobil untuk perjalanan luar Jabodetabek atau perjalanan menginap.",
            image: onBoarding3
        },
        {
            title: "Driver",
            body: "Booking Driver untuk menjemput dan mengantar ke lokasi pilihan kamu.",
            image: onBoarding4
        }
    ]

    const lobbyCard = [
        {
            title: "Operasional",
            body: "Booking mobil untuk perjalanan dalam Jabodetabek tanpa menginap.",
            icon: cardLobby1,
            onClick: () => navigate.push("/booking-car")
        },
        {
            title: "Perjalanan Dinas",
            body: "Booking mobil untuk perjalanan luar Jabodetabek atau perjalanan menginap.",
            icon: cardLobby2,
            onClick: () => navigate.push("/booking-car/perjadin")
        },
        {
            title: "Driver",
            body: "Booking Driver untuk menjemput dan mengantar ke lokasi pilihan kamu.",
            icon: cardLobby3,
            onClick: () => navigate.push("/booking-car/driver")
        }
    ]

  return (
    <Flex
        w="100%"
        h="100vh"
        bg="#F6FAFF"
        direction="column"
        alignItems="center"
        px="16px">
        <LobbyHeader
            leftLink={"/"}
            rightOnClick={onOpenPenjelasanFitur}
            textLeftLink={"Home"}
            textRightLink={"Penjelasan Fitur"} />
        <Flex
            direction="column"
            w="100%"
            alignItems="center">
            <img
                src={mascot}
                alt="mascot"
                style={{
                    width: "100px",
                    height: "auto"
                }}/>
            <p style={{
                fontSize:"16px",
                fontWeight:"500",
                textAlign:"center" }}>
                Pilih Kategori Booking Car
            </p>
        </Flex>
        {
            lobbyCard.map((card, index) =>{
                return (
                    <CardLobby
                        index={index}
                        title={card.title}
                        body={card.body}
                        ic={card.icon}
                        onClick={card.onClick} />
                )
            })
        }
        <Flex
            position="fixed"
            bottom="0"
            mb="20px"
            width="100%"
            maxWidth="500px"
            height="36px"
            justifyContent="center"
            alignItems="center"
            fontSize="12px"
            fontWeight={400}
        >
            © PT Paragon Technology and Innovation
        </Flex>
        {
            isDesktop ? (
                <>
                    <Modal isOpen={isOpenOnBoarding} onClose={onCloseOnBoarding} isCentered>
                        <ModalOverlay />
                        <ModalContent
                            w="336px"
                            h="480px"
                            bg="white"
                            zIndex={99}
                            borderRadius="28px">
                            <img
                                src={onBoardingItems[activeIndexOnBoarding].image}
                                alt="onboarding"
                                style={{
                                    width:"100%",
                                    height:"auto",
                                    borderTopRightRadius:"28px",
                                    borderTopLeftRadius:"28px"
                                }}/>
                            <ModalBody>
                                <OnBoardBody
                                    onBoardingItems={onBoardingItems}
                                    activeIndexOnBoarding={activeIndexOnBoarding}/>
                            </ModalBody>
                            <ModalFooter>
                                <ActionButtonOnBoarding
                                    onClickNext={handleNextOnBoarding}
                                    isLast={activeIndexOnBoarding === onBoardingItems.length-1}
                                    isDesktop={isDesktop}/>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Modal isOpen={isOpenPenjelasanFitur} onClose={onClosePenjelasanFitur} isCentered>
                        <ModalOverlay />
                        <ModalContent bg="white" zIndex={99}>
                            <ModalCloseButton />
                            <ModalBody>
                                <PenjelasanFiturContent/>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </>
            ) : (
                <>
                    <Drawer
                        isOpen={isOpenOnBoarding}
                        placement="bottom"
                        onClose={onCloseOnBoarding}
                    >
                        <DrawerOverlay />
                        <DrawerContent
                            bg="white"
                            height="492px"
                            borderTopRadius="28px"
                            padding="0px 0px 24px 0px"
                            justifyContent="center"
                            alignItems="center">
                            <img
                                src={onBoardingItems[activeIndexOnBoarding].image}
                                alt="onboarding"
                                style={{
                                    width:"100%",
                                    height:"auto",
                                    borderTopRightRadius:"28px",
                                    borderTopLeftRadius:"28px"
                            }}/>
                            <DrawerBody>
                                <OnBoardBody
                                    onBoardingItems={onBoardingItems}
                                    activeIndexOnBoarding={activeIndexOnBoarding}/>
                            </DrawerBody>
                            <DrawerFooter w="100%">
                                <ActionButtonOnBoarding
                                    onClickNext={handleNextOnBoarding}
                                    isLast={activeIndexOnBoarding === onBoardingItems.length-1}
                                    isDesktop={isDesktop}/>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                    <Drawer
                        isOpen={isOpenPenjelasanFitur}
                        placement="bottom"
                        onClose={onClosePenjelasanFitur}
                        finalFocusRef={btnRef}
                    >
                        <DrawerOverlay />
                        <DrawerContent
                            bg="white"
                            borderTopRadius="28px"
                            padding="20px 16px 24px 16px"
                            justifyContent="center"
                            alignItems="center">
                            <Box height="4px" width="32px" bg="#5C5C5C" mb={4} borderRadius="100px" />
                            <DrawerBody padding="0px" mt="16px">
                                <PenjelasanFiturContent/>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </>
            )
        }
    </Flex>
  );
}

const ActionButtonOnBoarding = ({onClickNext, isLast, isDesktop}) => {
    return (
        <Flex w="100%" gap="8px" flexDirection="column">
            <Button
                onClick={()=> onClickNext(false)}
                width="100%"
                height="36px"
                fontSize="14px"
                fontWeight="700"
                bgColor="#184973"
                color="white"
                _hover={{bgColor:!isDesktop?"#184973":"#6382A1"}}
                borderRadius="100">
                {isLast ? "Selesai" : "Lanjut"}
            </Button>
            <Button
                onClick={()=> onClickNext(true)}
                width="100%"
                height="36px"
                fontSize="14px"
                fontWeight="700"
                bgColor="white"
                color="#184973"
                _hover={{bgColor:!isDesktop?"white":"#E5E5E5"}}
                borderRadius="100">
                Lewati
            </Button>
        </Flex>
    )
}

const OnBoardBody = ({onBoardingItems, activeIndexOnBoarding}) => {
    return (
        <Flex
            w="100%"
            h="100%"
            direction="column">
            <Flex direction="row" gap="6px" justifyContent="center">
                {
                    onBoardingItems.map((item, index) =>{
                        return (
                            <Box
                                w={index === activeIndexOnBoarding ? "17.41px" : "5.71px"}
                                h="5.71px"
                                bg={index === activeIndexOnBoarding ? "#184973" : "#C2C2C2"}
                                key={index}
                                borderRadius={index === activeIndexOnBoarding ? "5px" : "100"}/>
                        )
                    })
                }
            </Flex>
            <Flex
                direction="column"
                w="100%"
                justifyContent="center"
                alignItems="center"
                pl="24px"
                pr="24px">
                <Flex
                    direction="column"
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    pt="16px"
                    px="16px"
                    gap="8px"
                    width="312px">
                    <p style={{
                        fontSize:"16px",
                        fontWeight:"700",
                        textAlign:"center" }}>
                        {
                            onBoardingItems[activeIndexOnBoarding].title.split("\n").map((item, index) =>{
                                return (
                                    <span key={index}>
                                                            {item}
                                        <br/>
                                                        </span>
                                )
                            })
                        }
                    </p>
                    <p style={{
                        fontSize:"12px",
                        fontWeight:"400",
                        textAlign:"center",
                        color:"#7A7A7A"
                    }}>
                        {onBoardingItems[activeIndexOnBoarding].body}
                    </p>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default LobbyView;