import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Textarea,
  Text,
  Radio,
  RadioGroup,
  Switch,
  useBreakpointValue,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import moment from "moment";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import circleRow from "../../../assets/img/bookingCar/circle-row.svg";

import id from "date-fns/locale/id";
import { MdAccessTime, MdArrowForward, MdOutlineDateRange } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";

import { format } from "date-fns";
import React, { useState, useRef } from "react";

import { isEmpty } from "lodash";
import { ACTIVE_BUTTON_COLOR, Session_Availalbe_Car } from "../../../utils/constant";
import { useHistory } from "react-router";
import { useGetQueryParams } from "../../../hooks/useGetQueryParams";
import TimePicker from "../MainCar/Modal/TimePicker";
import DateTimePicker from "../Component/DateTimePicker";
import useLocalStorage from "../../../hooks/useLocalStorage";
import OddEvenTooltipDrawer from "../MainCar/Tooltip/OddEvenTooltipDrawer";
import OddEvenTooltipModal from "../MainCar/Tooltip/OddEvenTooltipModal";
import CustomToast from "../../Toast/CustomToast";

const FormDate = ({ onClose: onCloseChangeDate }) => {
  const { params } = useGetQueryParams();

  const { replace } = useHistory();

  const [bookingDate, setBookingDate] = useState(
    () => new Date(decodeURIComponent(params.start_usage.split(" ")[0]))
  );

  const [startDate, setStartDate] = useState(new Date(params.start_usage.split(" ")[0]));
  const [finishDate, setFinishDate] = useState(new Date(params.finish_usage.split(" ")[0]));
  const [startUsage, setStartUsage] = useState(params.start_usage.split(" ")[1]);
  const [finishUsage, setFinishUsage] = useState(params.finish_usage.split(" ")[1]);
  const [purpose, setPurpose] = useState(params.purpose);
  const [capacity, setCapacity] = useState(params.capacity || "Semua");
  const [isOddEven, setIsOddEven] = useState(!isEmpty(params.license_plate_type));
  const inputRef = useRef([]);
  const bookingType = JSON.parse(localStorage.getItem("bookingFormFilterData")).booking_type;

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen: isTooltipOpen, onOpen: onTooltipOpen, onClose: onTooltipClose } = useDisclosure();

  const [, setLocal] = useLocalStorage(Session_Availalbe_Car, {});
  registerLocale("id", id);
  const toast = useToast();
  const formatDate = (date) => {
    return format(date, "EEEE, dd MMMM yyyy", { locale: id });
  };

  const handleStartUsageTime = (selectedTime) => {
    setStartUsage(selectedTime);
    onOpenFinishUsage();
  };
  const handleFinishUsageTime = (selectedTime) => {
    setFinishUsage(selectedTime);
  };
  const {
    isOpen: isOpenStartUsage,
    onOpen: onOpenStartUsage,
    onClose: onCloseStartUsage,
  } = useDisclosure();

  const {
    isOpen: isOpenFinishUsage,
    onOpen: onOpenFinishUsage,
    onClose: onCloseFinishUsage,
  } = useDisclosure();

  const {
    isOpen: isStartTimerOpen,
    onOpen: onStartTimerOpen,
    onClose: onStartTimerClose,
  } = useDisclosure();

  const {
    isOpen: isFinishTimerOpen,
    onOpen: onFinishTimerOpen,
    onClose: onFinishTimerClose,
  } = useDisclosure();

  const isValidForm = startUsage && finishUsage && purpose && capacity;

  const handleDateChange = (date, type) => {
    if (type === "start") setStartDate(date);
    else setFinishDate(date);
  };

  const handleTimeSelect = (time, type) => {
    if (type === "start") setStartUsage(time);
    else setFinishUsage(time);
  };

  const handleApply = () => {
    const filter = { ...params };

    if (startDate && startUsage) {
      filter["start_usage"] = `${format(
        bookingType === "Operasional" ? bookingDate : startDate,
        "yyyy-MM-dd"
      )} ${startUsage}`;
    }
    if (finishDate && finishUsage) {
      filter["finish_usage"] = `${format(
        bookingType === "Operasional" ? bookingDate : finishDate,
        "yyyy-MM-dd"
      )} ${finishUsage}`;
    }
    if (purpose) {
      filter["purpose"] = purpose;
    }
    if (capacity && capacity !== "Semua") {
      filter["capacity"] = capacity.replace(" Orang", "");
    }
    if (!isOddEven) {
      filter["license_plate_type"] = "";
    } else {
      filter["license_plate_type"] =
        new Date(bookingType === "Operasional" ? bookingDate : startDate).getDate() % 2 === 0
          ? "genap"
          : "ganjil";
    }

    if (filter["start_usage"] && filter["finish_usage"]) {
      const selectedStartDateTime = moment(filter["start_usage"].split(" ")[0]).set({
        hour: parseInt(filter["start_usage"].split(" ")[1].split(":")[0]),
        minute: parseInt(filter["start_usage"].split(" ")[1].split(":")[1]),
      });

      const selectedFinishDateTime = moment(filter["finish_usage"].split(" ")[0]).set({
        hour: parseInt(filter["finish_usage"].split(" ")[1].split(":")[0]),
        minute: parseInt(filter["finish_usage"].split(" ")[1].split(":")[1]),
      });

      if (selectedStartDateTime.isAfter(selectedFinishDateTime)) {
        toast({
          position: "top",
          render: () => (
            <CustomToast
              title="Waktu tidak valid"
              description="Waktu pergi tidak boleh lebih besar dari waktu pulang"
              status="error"
            />
          ),
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }

    const localDate = new Date(filter["start_usage"].split(" ")[0]);
    const currentDate = new Date();
    const currentTime = new Date()
      .toLocaleTimeString("id-ID", {
        hour: "2-digit",
        minute: "2-digit",
      })
      .split(".");

    // kondisi untuk mengecek apakah jam yang dipilih lebih kecil dari jam saat ini
    const isTimeLessThanCurrentTime =
      currentDate.getDate() === localDate.getDate() &&
      currentDate.getMonth() === localDate.getMonth() &&
      currentDate.getFullYear() === localDate.getFullYear() &&
      ((currentTime[0] === filter["start_usage"].split(" ")[1].split(":")[0] &&
        currentTime[1] > filter["start_usage"].split(" ")[1].split(":")[1]) ||
        currentTime[0] > filter["start_usage"].split(" ")[1].split(":")[0]);

    if (isTimeLessThanCurrentTime) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Jam tidak valid"
            description="Jam pergi tidak boleh kurang dari jam saat ini"
            status="error"
          />
        ),
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLocal(filter);
    replace({
      pathname: "/booking-car/available-car",
      search: new URLSearchParams(filter).toString(),
    });

    onCloseChangeDate();
  };

  return (
    <Box>
      <VStack spacing={4}>
        <Box width="100%" px="16px" pt="16px" pb="24px" borderBottom="1px solid #EFEFEF">
          <Box
            bg="#F6FAFF"
            padding="12px"
            borderRadius="12px"
            fontSize="14px"
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
          >
            <VStack spacing={4}>
              {bookingType === "Operasional" ? (
                <>
                  {/* Tanggal Booking for Operasional */}
                  <FormControl isRequired zIndex={9}>
                    <FormLabel fontSize="14px">Tanggal Booking</FormLabel>
                    <InputGroup
                      bg="white"
                      sx={{
                        ".react-datepicker-wrapper": {
                          width: "100%",
                        },
                        ".react-datepicker__tab-loop": {
                          zIndex: 9,
                        },
                      }}
                    >
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Icon as={MdOutlineDateRange} color="#222222" />}
                      />
                      <DatePicker
                        selected={bookingDate}
                        onChange={(date) => setBookingDate(date)}
                        dateFormat="eeee, dd MMMM yyyy"
                        placeholderText="Pilih tanggal"
                        customInput={
                          <Input
                            fontSize="14px"
                            value={bookingDate ? formatDate(bookingDate) : ""}
                            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                            border="none"
                            paddingLeft="32px"
                          />
                        }
                        minDate={new Date()}
                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
                        locale="id"
                        zIndex={9}
                      />
                    </InputGroup>
                  </FormControl>

                  {/* Jam for Operasional */}
                  <Flex
                    width="100%"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    gap="8px"
                    flexDir="row"
                  >
                    {/* Jam Pergi */}
                    <FormControl isRequired>
                      <FormLabel fontSize="14px">Jam Pergi</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Icon as={MdAccessTime} color="#222222" />}
                        />
                        <Input
                          bg="white"
                          borderRadius="8px"
                          padding="8px"
                          value={startUsage}
                          onClick={onOpenStartUsage}
                          isReadOnly
                          cursor="pointer"
                          paddingLeft="32px"
                          placeholder="Pilih Jam"
                          fontSize="14px"
                        />
                      </InputGroup>
                      <TimePicker
                        isOpen={isOpenStartUsage}
                        onClose={onCloseStartUsage}
                        onTimeSelect={handleStartUsageTime}
                        type="start"
                        startUsage={startUsage}
                        finishUsage={finishUsage}
                        startDate={bookingDate}
                        finishDate={bookingDate}
                      />
                    </FormControl>

                    {/* Arrow */}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100%"
                      padding="8px"
                      bg="white"
                      marginBottom="4px"
                    >
                      <MdArrowForward size={16} />
                    </Box>

                    {/* Jam Pulang */}
                    <FormControl isRequired>
                      <FormLabel fontSize="14px">Jam Pulang</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<Icon as={MdAccessTime} color="#222222" />}
                        />
                        <Input
                          bg="white"
                          borderRadius="8px"
                          padding="8px"
                          value={finishUsage}
                          onClick={onOpenFinishUsage}
                          isReadOnly
                          cursor="pointer"
                          paddingLeft="32px"
                          placeholder="Pilih Jam"
                          fontSize="14px"
                        />
                      </InputGroup>
                      <TimePicker
                        isOpen={isOpenFinishUsage}
                        onClose={onCloseFinishUsage}
                        onTimeSelect={handleFinishUsageTime}
                        type="finish"
                        startUsage={startUsage}
                        finishUsage={finishUsage}
                        startDate={bookingDate}
                        finishDate={bookingDate}
                      />
                    </FormControl>
                  </Flex>
                </>
              ) : (
                // bookingType === "Perjadin"
                <>
                  {/* Waktu for Perjadin */}
                  <Flex
                    w="100%"
                    ref={(el) => (inputRef.current[0] = el)}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="12px"
                  >
                    <DateTimePicker
                      type="start"
                      label={"Tanggal Pergi"}
                      isOpen={isStartTimerOpen}
                      onOpen={onStartTimerOpen}
                      onClose={onStartTimerClose}
                      startUsage={startUsage}
                      finishUsage={finishUsage}
                      value={startUsage}
                      onTimeSelect={(time) => handleTimeSelect(time, "start")}
                      selectedDate={startDate}
                      onDateChange={(date) => handleDateChange(date, "start")}
                      startDate={startDate}
                      finishDate={finishDate}
                    />
                    <img src={circleRow} alt="row" />
                    <DateTimePicker
                      type="finish"
                      label={"Tanggal Pulang"}
                      isOpen={isFinishTimerOpen}
                      onOpen={onFinishTimerOpen}
                      onClose={onFinishTimerClose}
                      startUsage={startUsage}
                      finishUsage={finishUsage}
                      value={finishUsage}
                      onTimeSelect={(time) => handleTimeSelect(time, "finish")}
                      selectedDate={finishDate}
                      onDateChange={(date) => handleDateChange(date, "finish")}
                      startDate={startDate}
                      finishDate={finishDate}
                    />
                  </Flex>
                </>
              )}

              {/* Keperluan Booking */}
              <FormControl isRequired>
                <FormLabel fontSize="14px">Keperluan Booking</FormLabel>
                <Textarea
                  onChange={(e) => setPurpose(e.target.value)}
                  value={purpose}
                  maxLength={200}
                  bg="white"
                  borderRadius="8px"
                  gap="8px"
                  padding="8px"
                  z
                  fontSize="14px"
                />
              </FormControl>

              {/* Kapasitas Mobil */}
              <FormControl as="fieldset" isRequired>
                <FormLabel as="legend" fontSize="14px">
                  Kapasitas Mobil
                </FormLabel>
                <RadioGroup
                  defaultValue={
                    capacity === "Semua" ? capacity : !capacity ? "Semua" : `${capacity} Orang`
                  }
                  onChange={setCapacity}
                  sx={{
                    ".chakra-radio__label": {
                      fontSize: "14px",
                    },
                  }}
                >
                  <HStack align="start">
                    <Box
                      cursor="pointer"
                      bg="white"
                      border="0.5px solid #D6D6D6"
                      borderRadius="8px"
                      gap="8px"
                      padding="8px 10px"
                      z
                    >
                      <Radio value="Semua" isFocusable={false}>
                        Semua
                      </Radio>
                    </Box>
                    <Box
                      cursor="pointer"
                      bg="white"
                      border="0.5px solid #D6D6D6"
                      borderRadius="8px"
                      gap="8px"
                      padding="8px 10px"
                      z
                    >
                      <Radio value="5 Orang" isFocusable={false}>
                        5 Orang
                      </Radio>
                    </Box>
                    <Box
                      cursor="pointer"
                      bg="white"
                      border="0.5px solid #D6D6D6"
                      borderRadius="8px"
                      gap="8px"
                      padding="8px 10px"
                      z
                    >
                      <Radio value="7 Orang" isFocusable={false}>
                        7 Orang
                      </Radio>
                    </Box>
                  </HStack>
                </RadioGroup>
              </FormControl>

              {/* Ganjil Genap */}
              <FormControl display="flex" flexDirection="column" gap="8px">
                <Flex alignItems="center">
                  <Text fontWeight="500">Melewati Ganjil Genap?</Text>
                  <Icon
                    as={IoMdInformationCircleOutline}
                    boxSize={5}
                    color="gray.500"
                    cursor="pointer"
                    onClick={onTooltipOpen}
                    marginLeft="8px"
                  />
                </Flex>

                <Flex alignItems="center" justifyContent="space-between" gap="8px" width="78px">
                  <FormLabel fontSize="14px" fontWeight="400" color="#5E5E5E" m="0">
                    {isOddEven ? "Ya" : "Tidak"}
                  </FormLabel>

                  <Switch
                    colorScheme="blue"
                    size="md"
                    isChecked={isOddEven}
                    onChange={() => setIsOddEven(!isOddEven)}
                  />
                </Flex>
              </FormControl>
            </VStack>
          </Box>
        </Box>
      </VStack>

      {/* Footer Button */}
      <Flex alignItems="center" justifyContent="end" gap="16px" mt="16px" mb="24px">
        <Button
          border="1px"
          borderColor={ACTIVE_BUTTON_COLOR}
          padding="6px 12px"
          borderRadius="100px"
          fontWeight={400}
          fontSize="14px"
          letterSpacing="0.1px"
          color={ACTIVE_BUTTON_COLOR}
          variant="outline"
          onClick={onCloseChangeDate}
          width="114px"
          height="32px"
        >
          Batal
        </Button>
        <Button
          fontSize="14px"
          bgColor={ACTIVE_BUTTON_COLOR}
          color="white"
          borderColor={ACTIVE_BUTTON_COLOR}
          border="1px"
          padding="6px 12px"
          mr="16px"
          borderRadius="100px"
          fontWeight={400}
          width="114px"
          height="32px"
          isDisabled={!isValidForm}
          onClick={handleApply}
          letterSpacing="0.1px"
        >
          Terapkan
        </Button>
      </Flex>
      {isMobile ? (
        <OddEvenTooltipDrawer isOpen={isTooltipOpen} onClose={onTooltipClose} />
      ) : (
        <OddEvenTooltipModal isOpen={isTooltipOpen} onClose={onTooltipClose} />
      )}
    </Box>
  );
};

export default FormDate;
