import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Spinner,
  Switch,
  Text,
  Textarea,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";

import moment from "moment";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getListBookedCarDate } from "../../../api/fetchBookedCar";
import circleRow from "../../../assets/img/bookingCar/circle-row.svg";
import TimePicker from "./Modal/TimePicker";

import { MdAccessTime, MdArrowForward, MdOutlineDateRange } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Axios from "../../../utils/axiosService";
import { Session_Availalbe_Car } from "../../../utils/constant";
import OddEvenTooltipDrawer from "./Tooltip/OddEvenTooltipDrawer";
import OddEvenTooltipModal from "./Tooltip/OddEvenTooltipModal";
import CustomToast from "../../Toast/CustomToast";
import DateTimePicker from "../Component/DateTimePicker";

import { format } from "date-fns";
import SyaratKetentuan from "./Modal/SyaratKetentuan";
import { FetchDriverRequest } from "../../../api/fetchDriverRequest";

const BookingForm = ({ bookingType, isChooseCarFirst, idCar }) => {
  const [excludeDate, setExcludeDate] = useState([]);
  const navigate = useHistory();
  const toast = useToast();

  registerLocale("id", id);
  const formatDate = (date) => {
    return format(date, "EEEE, dd MMMM yyyy", { locale: id });
  };

  const [bookingDate, setBookingDate] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startUsage, setStartUsage] = useState("");
  const [finishDate, setFinishDate] = useState(null);

  const [finishUsage, setFinishUsage] = useState("");
  const [purpose, setPurpose] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [capacityValue, setCapacityValue] = useState("Semua");
  const [isOddEven, setIsOddEven] = useState(false);
  const inputRef = useRef([]);
  const [driverRequest, setDriverRequestId] = useState();

  const {
    isOpen: isOpenStartUsage,
    onOpen: onOpenStartUsage,
    onClose: onCloseStartUsage,
  } = useDisclosure();

  const {
    isOpen: isOpenFinishUsage,
    onOpen: onOpenFinishUsage,
    onClose: onCloseFinishUsage,
  } = useDisclosure();

  const {
    isOpen: isStartTimerOpen,
    onOpen: onStartTimerOpen,
    onClose: onStartTimerClose,
  } = useDisclosure();

  const {
    isOpen: isFinishTimerOpen,
    onOpen: onFinishTimerOpen,
    onClose: onFinishTimerClose,
  } = useDisclosure();

  const { isOpen: isSyaratOpen, onOpen: onSyaratOpen, onClose: onSyaratClose } = useDisclosure();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen: isTooltipOpen, onOpen: onTooltipOpen, onClose: onTooltipClose } = useDisclosure();

  useEffect(() => {
    const fetchDataCarBooked = async () => {
      const dateBooked = await getListBookedCarDate({ id: idCar });
      setExcludeDate(dateBooked);
    };
    fetchDataCarBooked();
  }, [idCar]);

  const handleStartUsageTime = (selectedTime) => {
    setStartUsage(selectedTime);
    onOpenFinishUsage();
  };

  const handleFinishUsageTime = (selectedTime) => {
    setFinishUsage(selectedTime);
  };

  const isValidForm = () => {
    if (bookingType === "Operasional") {
      return bookingDate && startUsage && finishUsage && purpose && capacityValue;
    } else {
      return startDate && finishDate && startUsage && finishUsage && purpose && capacityValue;
    }
  };

  const handleDateChange = (date, type) => {
    if (type === "start") setStartDate(date);
    else setFinishDate(date);
  };

  const handleTimeSelect = (time, type) => {
    if (type === "start") setStartUsage(time);
    else setFinishUsage(time);
  };

  const consentConfirm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { driverRequest } = await FetchDriverRequest();
      setDriverRequestId(driverRequest);

      if (!driverRequest.is_consent) {
        onSyaratOpen();
      } else {
        handleSubmit(e);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    let formattedStartDate, formattedEndDate;
    if (bookingType === "Operasional") {
      const localDate = new Date(bookingDate);
      const timezoneOffset = localDate.getTimezoneOffset() * 60000;
      formattedStartDate = formattedEndDate = new Date(localDate.getTime() - timezoneOffset)
        .toISOString()
        .split("T")[0];
    } else {
      const timezoneOffset = startDate.getTimezoneOffset() * 60000;
      formattedStartDate = new Date(startDate.getTime() - timezoneOffset)
        .toISOString()
        .split("T")[0];
      formattedEndDate = new Date(finishDate.getTime() - timezoneOffset)
        .toISOString()
        .split("T")[0];
    }

    const currentDateTime = moment();
    const selectedStartDateTime = moment(`${formattedStartDate} ${startUsage}`);
    const selectedFinishDateTime = moment(`${formattedEndDate} ${finishUsage}`);

    if (selectedStartDateTime.isBefore(currentDateTime)) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Waktu tidak valid"
            description="Waktu pergi tidak boleh kurang dari waktu saat ini"
            status="error"
          />
        ),
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (selectedFinishDateTime.isBefore(selectedStartDateTime)) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Waktu tidak valid"
            description="Waktu pulang tidak boleh kurang dari waktu pergi"
            status="error"
          />
        ),
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const bookingFormFilterData = {
      start_usage: `${formattedStartDate} ${startUsage}`,
      finish_usage: `${formattedEndDate} ${finishUsage}`,
      purpose: purpose,
      booking_type: bookingType,
    };

    if (isOddEven) {
      bookingFormFilterData["license_plate_type"] =
        (bookingType === "Operasional" ? bookingDate : startDate).getDate() % 2 === 0
          ? "genap"
          : "ganjil";
    }

    if (idCar) {
      bookingFormFilterData["id"] = idCar;
    }

    if (capacityValue.toLowerCase() !== "semua") {
      bookingFormFilterData["capacity"] = Number(capacityValue[0]) || 5;
    }

    if (!isChooseCarFirst) {
      localStorage.setItem("bookingFormFilterData", JSON.stringify(bookingFormFilterData));

      const baseUrl = "/booking-car/available-car";
      const queryString = new URLSearchParams(bookingFormFilterData).toString();
      const urlWithQuery = `${baseUrl}?${queryString}`;
      navigate.push(urlWithQuery);
    } else {
      setIsLoading(true);
      Axios.post("/car/booking", {
        car_id: Number(bookingFormFilterData.id),
        booking_status: "Booking Created",
        car_user_id: parseInt(localStorage.getItem("id_user")),
        purpose: bookingFormFilterData.purpose,
        start_usage: bookingFormFilterData.start_usage,
        finish_usage: bookingFormFilterData.finish_usage,
        booking_type: bookingFormFilterData.booking_type,
      })
        .then((res) => {
          toast({
            position: "top",
            render: () => <CustomToast title="Berhasil booking mobil" status="success" />,
            duration: 3000,
            isClosable: true,
          });
          localStorage.removeItem(Session_Availalbe_Car);
          const bookingId = res.data.data.data.id;
          navigate.push("/booking-car/booking-details/" + bookingId);
        })
        .catch((err) => {
          let errorMessage = "An error has occurred";
          if (
            err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.length > 0
          ) {
            errorMessage = `An error has occurred: ${err.response.data.errors[0].message}`;
          } else if (err.message) {
            errorMessage = `An error has occurred: ${err.message}`;
          }

          toast({
            position: "top",
            render: () => <CustomToast title="Error" description={errorMessage} status="error" />,
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box width="100%" padding="24px 16px" borderBottom="1px solid #EFEFEF">
      <Box bg="#F6FAFF" padding="12px" borderRadius="12px" fontSize="14px">
        <form onSubmit={consentConfirm}>
          <VStack spacing={4}>
            {bookingType === "Operasional" ? (
              <>
                {/* Tanggal Booking for Operasional */}
                <FormControl isRequired zIndex={9}>
                  <FormLabel fontSize="14px">Tanggal Booking</FormLabel>
                  <InputGroup
                    bg="white"
                    sx={{
                      ".react-datepicker-wrapper": {
                        width: "100%",
                      },
                      ".react-datepicker__tab-loop": {
                        zIndex: 9,
                      },
                    }}
                  >
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Icon as={MdOutlineDateRange} color="#222222" />}
                    />
                    <DatePicker
                      selected={bookingDate}
                      onChange={(date) => setBookingDate(date)}
                      dateFormat="eeee, dd MMMM yyyy"
                      excludeDates={excludeDate}
                      placeholderText="Pilih tanggal"
                      customInput={
                        <Input
                          fontSize="14px"
                          value={bookingDate ? formatDate(bookingDate) : ""}
                          boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                          border="none"
                          paddingLeft="32px"
                        />
                      }
                      minDate={new Date()}
                      maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
                      locale="id"
                      zIndex={9}
                    />
                  </InputGroup>
                </FormControl>

                {/* Jam for Operasional */}
                <Flex
                  width="100%"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  gap="8px"
                  flexDir="row"
                >
                  {/* Jam Pergi */}
                  <FormControl isRequired>
                    <FormLabel fontSize="14px">Jam Pergi</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Icon as={MdAccessTime} color="#222222" />}
                      />
                      <Input
                        bg="white"
                        borderRadius="8px"
                        padding="8px"
                        value={startUsage}
                        onClick={onOpenStartUsage}
                        isReadOnly
                        cursor="pointer"
                        paddingLeft="32px"
                        placeholder="Pilih Jam"
                        fontSize="14px"
                      />
                    </InputGroup>
                    <TimePicker
                      isOpen={isOpenStartUsage}
                      onClose={onCloseStartUsage}
                      onTimeSelect={handleStartUsageTime}
                      type="start"
                      startUsage={startUsage}
                      finishUsage={finishUsage}
                      startDate={bookingDate}
                      finishDate={bookingDate}
                    />
                  </FormControl>

                  {/* Arrow */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="100%"
                    padding="8px"
                    bg="white"
                    marginBottom="4px"
                  >
                    <MdArrowForward size={16} />
                  </Box>

                  {/* Jam Pulang */}
                  <FormControl isRequired>
                    <FormLabel fontSize="14px">Jam Pulang</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Icon as={MdAccessTime} color="#222222" />}
                      />
                      <Input
                        bg="white"
                        borderRadius="8px"
                        padding="8px"
                        value={finishUsage}
                        onClick={onOpenFinishUsage}
                        isReadOnly
                        cursor="pointer"
                        paddingLeft="32px"
                        placeholder="Pilih Jam"
                        fontSize="14px"
                      />
                    </InputGroup>
                    <TimePicker
                      isOpen={isOpenFinishUsage}
                      onClose={onCloseFinishUsage}
                      onTimeSelect={handleFinishUsageTime}
                      type="finish"
                      startUsage={startUsage}
                      finishUsage={finishUsage}
                      startDate={bookingDate}
                      finishDate={bookingDate}
                    />
                  </FormControl>
                </Flex>
              </>
            ) : (
              // bookingType === "Perjadin"
              <>
                {/* Waktu for Perjadin */}
                <Flex
                  w="100%"
                  ref={(el) => (inputRef.current[0] = el)}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="12px"
                >
                  <DateTimePicker
                    type="start"
                    label={"Tanggal Pergi"}
                    isOpen={isStartTimerOpen}
                    onOpen={onStartTimerOpen}
                    onClose={onStartTimerClose}
                    startUsage={startUsage}
                    finishUsage={finishUsage}
                    value={startUsage}
                    onTimeSelect={(time) => handleTimeSelect(time, "start")}
                    selectedDate={startDate}
                    onDateChange={(date) => handleDateChange(date, "start")}
                    startDate={startDate}
                    finishDate={finishDate}
                    excludeDate={excludeDate}
                  />
                  <img src={circleRow} alt="row" />
                  <DateTimePicker
                    type="finish"
                    label={"Tanggal Pulang"}
                    isOpen={isFinishTimerOpen}
                    onOpen={onFinishTimerOpen}
                    onClose={onFinishTimerClose}
                    startUsage={startUsage}
                    finishUsage={finishUsage}
                    value={finishUsage}
                    onTimeSelect={(time) => handleTimeSelect(time, "finish")}
                    selectedDate={finishDate}
                    onDateChange={(date) => handleDateChange(date, "finish")}
                    startDate={startDate}
                    finishDate={finishDate}
                    excludeDate={excludeDate}
                  />
                </Flex>
              </>
            )}

            {/* Keperluan Booking */}
            <FormControl isRequired>
              <FormLabel fontSize="14px">Keperluan Booking</FormLabel>
              <Textarea
                onChange={(e) => setPurpose(e.target.value)}
                placeholder="Contoh: market visit, dll"
                maxLength={200}
                bg="white"
                borderRadius="8px"
                gap="8px"
                padding="8px"
                fontSize="14px"
                sx={{
                  "::placeholder": {
                    fontStyle: "italic",
                  },
                }}
              />
            </FormControl>

            {!isChooseCarFirst ? (
              <>
                {/* Kapasitas Mobil */}
                <FormControl as="fieldset" isRequired>
                  <FormLabel as="legend" fontSize="14px">
                    Kapasitas Mobil
                  </FormLabel>
                  <RadioGroup
                    defaultValue="Semua"
                    onChange={setCapacityValue}
                    sx={{
                      ".chakra-radio__label": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    <HStack align="start">
                      <Box
                        cursor="pointer"
                        bg="white"
                        border="0.5px solid #D6D6D6"
                        borderRadius="8px"
                        gap="8px"
                        padding="8px 10px"
                      >
                        <Radio value="Semua" isFocusable={false}>
                          Semua
                        </Radio>
                      </Box>
                      <Box
                        cursor="pointer"
                        bg="white"
                        border="0.5px solid #D6D6D6"
                        borderRadius="8px"
                        gap="8px"
                        padding="8px 10px"
                      >
                        <Radio value="5 Orang" isFocusable={false}>
                          5 Orang
                        </Radio>
                      </Box>
                      <Box
                        cursor="pointer"
                        bg="white"
                        border="0.5px solid #D6D6D6"
                        borderRadius="8px"
                        gap="8px"
                        padding="8px 10px"
                      >
                        <Radio value="7 Orang" isFocusable={false}>
                          7 Orang
                        </Radio>
                      </Box>
                    </HStack>
                  </RadioGroup>
                </FormControl>

                {/* Ganjil Genap */}
                <FormControl display="flex" flexDirection="column" gap="8px">
                  <Flex alignItems="center">
                    <Text fontWeight="500">Melewati Ganjil Genap?</Text>
                    <Icon
                      as={IoMdInformationCircleOutline}
                      boxSize={5}
                      color="gray.500"
                      cursor="pointer"
                      onClick={onTooltipOpen}
                      marginLeft="8px"
                    />
                  </Flex>

                  <Flex alignItems="center" justifyContent="space-between" gap="8px" width="78px">
                    <FormLabel fontSize="14px" fontWeight="400" color="#5E5E5E" m="0">
                      {isOddEven ? "Ya" : "Tidak"}
                    </FormLabel>

                    <Switch
                      colorScheme="blue"
                      size="md"
                      isChecked={isOddEven}
                      onChange={() => setIsOddEven(!isOddEven)}
                    />
                  </Flex>
                </FormControl>
              </>
            ) : null}

            {/* Submit Form */}
            <Button
              width="100%"
              bg="#184973"
              color="white"
              height="40px"
              borderRadius="100px"
              fontSize="14px"
              fontWeight="500"
              isDisabled={!isValidForm()}
              isLoading={isLoading}
              type="submit"
              _hover={{ bgColor: isMobile ? "#184973" : "#E2E8F0" }}
            >
              {isChooseCarFirst ? "Book Mobil" : "Cari Mobil"}
              {isLoading && <Spinner size={"sm"} ml="5px" />}
            </Button>
          </VStack>
        </form>
      </Box>
      {isMobile ? (
        <OddEvenTooltipDrawer isOpen={isTooltipOpen} onClose={onTooltipClose} />
      ) : (
        <OddEvenTooltipModal isOpen={isTooltipOpen} onClose={onTooltipClose} />
      )}
      <SyaratKetentuan
        isOpen={isSyaratOpen}
        onClose={onSyaratClose}
        handleSubmit={handleSubmit}
        driverRequest={driverRequest}
      />
    </Box>
  );
};

export default BookingForm;
