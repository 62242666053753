import { Box, Flex } from "@chakra-ui/react";
import React from "react";

import { formatYYYYMMDDToDay } from "../../../../../utils/formatDate";
import CarAttributes from "../../../Component/CarAttributes";
import CarLocation from "../../../Component/CarLocation";
import LeavingReturningTime from "../../../Component/LeavingReturningTime";

const DetailCarSection = ({
  id,
  name,
  brand,
  capacity,
  car_status,
  fuel,
  image,
  last_service,
  license_plate,
  transmission,
  color,
  current_location,
  location_url,
  book_details,
}) => {
  return (
    <Flex
      borderRadius="12px"
      p="12px"
      bg=" white"
      boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
      direction="column"
    >
      <CarAttributes
        brand={brand}
        name={name}
        car_status={car_status}
        license_plate={license_plate}
        capacity={capacity}
        transmission={transmission}
        fuel={fuel}
        color={color}
        last_service={last_service}
        image={image}
        showCarStatus={true}
      />
      {car_status === "available" && (
        <>
          <Box borderBottom="2px dashed" borderColor="gray.200" width="100%" my="8px" />
          <CarLocation current_location={current_location} location_url={location_url} />
        </>
      )}
      {car_status === "available" && book_details && (
        <>
          <Box borderBottom="2px dashed" borderColor="gray.200" width="100%" my="8px" />
          <LeavingReturningTime
            leavingDate={formatYYYYMMDDToDay(book_details.start_usage.split(" ")[0])}
            leavingTime={book_details.start_usage.split(" ")[1]}
            returningDate={formatYYYYMMDDToDay(book_details.finish_usage.split(" ")[0])}
            returningTime={book_details.finish_usage.split(" ")[1]}
          />
        </>
      )}
    </Flex>
  );
};

export default DetailCarSection;
