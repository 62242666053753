import React from 'react'
import {
    Flex,
} from "@chakra-ui/react";
import Header from "../../../../components/SecondaryHeader";
import BookingCarDriverForm from "../../../../components/BookingCar/Component/BookingCarDriverForm";
import Contact from "../../../../components/CTA/Contact";

function BookingCarDriver(){
    return (
        <Flex
            w="100%"
            bg="white"
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Header backLink={"/"} title={"Driver"} />
            <Flex
                w="100%"
                bg="white"
                direction="column"
                alignItems="center"
                justifyContent="center"
                p="16px">
                <Flex
                    w="100%"
                    bg="white"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    bgColor="#F6FAFF"
                    borderRadius="12px"
                    padding="12px">
                    <BookingCarDriverForm/>
                </Flex>
            </Flex>
            <Contact/>
            <Flex
                bottom="0"
                my="20px"
                width="100%"
                maxWidth="500px"
                height="36px"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
                fontWeight={400}
            >
                © PT Paragon Technology and Innovation
            </Flex>
        </Flex>
    )
}



export default BookingCarDriver;