import React from "react";
import BookingCarDetails from "../../../../components/BookingCar/MainCar/BookingDetails/BookingCarDetails";
import { Flex } from "@chakra-ui/react";

function BookingCarDetailsView() {
  return (
    <>
      <Flex flexDir="column" minH="100vh">
        <BookingCarDetails />
      </Flex>
    </>
  );
}

export default BookingCarDetailsView;
