import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Box,
} from '@chakra-ui/react';

const DrawerRounded = ({ isOpen, onClose, title, body, footer }) => {
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        bg="white"
        maxHeight="90vh"
        borderTopRadius="32px"
      >
        <Box 
          width="32px" 
          height="4px" 
          bg="#5C5C5C" 
          borderRadius="100px" 
          margin="16px auto"
        />
        <DrawerHeader
          p="0 16px"
          fontSize="16px"
          fontWeight="700"
          lineHeight="22.4px"
          color="#222222"
          mt="16px"
        >
          {title}
        </DrawerHeader>
        
        <DrawerBody 
          px="16px" 
          py="0" 
          mt="8px" 
          mb="24px"
        >
          {body}
        </DrawerBody>

        {footer && (
          <DrawerFooter
            px="16px"
            py="16px"
            borderTop="1px solid"
            borderColor="gray.200"
          >
            {footer}
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerRounded;