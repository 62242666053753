import React, { useState } from "react";
import moment from "moment/moment";
import {
  Flex,
  Box,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import MainHeader from "../../../../../../components/BookingRoom/Header/MainHeader";
import styles from "./hallroom.module.css";

import MultiStepsForm from "./FormSteps";
import CustomToast from "../../../../../../components/Toast/CustomToast";

function HallRoomForm({
  params,
  toast,
  onClose,
  isOpen,
  onOpen,
  roomData,
  formData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minTime,
  setMinTime,
  dateNow,
  dateMax,
  timeNow,
  setIsWeeklyMeeting,
  isWeeklyMeeting,
  isLoading,
  setIsLoading,
  setFormData,
  handleSubmissionData,
  checkAttendee,
  checkBookingData1,
  checkBookingData2,
  checkBookingData3,
  checkBookingData4,
}) {
  moment.locale();

  const [step, setStep] = useState(1);
  const [aulaLayoutType, setAulaLayoutType] = useState("");
  const [chairType, setChairType] = useState("");

  const handleInput = (event) => {
    let tempFormData = formData;
    tempFormData[event.target.name] = event.target.value;

    if (event.target.name === "isWeeklyMeeting") {
      setIsWeeklyMeeting(!isWeeklyMeeting);
      tempFormData["isWeeklyMeeting"] = !isWeeklyMeeting;
    }

    if (event.target.name === "booking_at") {
      setStartDate("");
      setEndDate("");
    }

    if (event.target.name === "start_usage") {
      if (tempFormData[`booking_at`] === dateNow) {
        if (event.target.value < minTime) {
          tempFormData["start_usage"] = timeNow;
          event.target.value = timeNow;
          setMinTime(event.target.value);
          setStartDate(timeNow);
        } else {
          setStartDate(event.target.value);
        }
      } else {
        setStartDate(event.target.value);
      }
    }

    if (event.target.name === "finish_usage") {
      if (tempFormData[`booking_at`] === dateNow) {
        if (event.target.value < minTime && event.target.value < tempFormData["start_usage"]) {
          tempFormData["finish_usage"] = moment().add(1, "minutes").format("HH:mm");
          event.target.value = moment().add(1, "minutes").format("HH:mm");
          setEndDate(tempFormData["finish_usage"]);
        } else {
          setEndDate(event.target.value);
        }
      } else {
        setEndDate(event.target.value);
      }
    }

    if (event.target.name === "booking_at") {
      if (event.target.value === dateNow && minTime < timeNow) {
        setMinTime(timeNow);
      }
    }

    setFormData(tempFormData);
  };

  const RadioChangeHandler = (event, name, className) => {
    var tempFormData = formData;

    if (className === "input") {
      tempFormData[name] = event.target.value;
    } else {
      tempFormData[name] = event;

      if (name === "selectedSeatOption") {
        setChairType(event);
      }
    }

    setFormData(tempFormData);
  };

  const RoomLayoutHandler = (event) => {
    var tempFormData = formData;
    tempFormData["aulaChosenLayout"] = event;
    setFormData(tempFormData);
    setAulaLayoutType(event);
  };

  const errorMessage = (msg) => {
    toast({
      position: "top",
      render: () => <CustomToast title="Error" description={msg} status="error" />,
      duration: 3500,
      isClosable: true,
    });
  };

  const validateStepOne = async (tempFormData, msg, tempStep) => {
    let returnedDataStatus = false;

    let attendee = parseInt(tempFormData.attendees);
    let returnCheckedData = await checkAttendee(attendee);

    let tempStartUsage = new Date(tempFormData.booking_at + " " + tempFormData.start_usage);
    let tempFinishUsage = new Date(tempFormData.booking_at + " " + tempFormData.finish_usage);

    if (tempFormData.start_usage.toString() !== "" && tempFormData.finish_usage.toString() !== "") {
      if (
        tempFormData.booking_purpose.toString() === "" ||
        tempFormData.user_contact.toString() === "" ||
        tempFormData.attendees.toString() === ""
      ) {
        msg = `Please fill all fields in Step ${tempStep}`;
        errorMessage(msg);
      } else if (tempFormData.start_usage > tempFormData.finish_usage) {
        msg = "Start Time cannot be greater than End Time";
        errorMessage(msg);
      } else if (parseInt(tempFormData.attendees) < 1) {
        msg = "The amount of attendees cannot be less than 1 person!";
        errorMessage(msg);
      } else if (returnCheckedData.isMoreThanCapacity) {
        msg = "The amount of attendees exceeds the room's limit!";
        errorMessage(msg);
      } else if (tempFormData["user_contact"].length < 8) {
        msg = "Invalid phone number";
        errorMessage(msg);
      } else {
        let checkData1 = await checkBookingData1(tempStartUsage, tempFinishUsage);
        let checkData2 = await checkBookingData2(tempStartUsage, tempFinishUsage);
        let checkData3 = await checkBookingData3(tempStartUsage, tempFinishUsage);
        let checkData4 = await checkBookingData4(tempStartUsage, tempFinishUsage);

        if (checkData1 && checkData2 && checkData3 && checkData4) {
          returnedDataStatus = true;
        } else {
          msg = "Some of the schedules have been booked with other bookings";
          errorMessage(msg);
        }
      }
    } else {
      msg = `Please fill all fields in Step ${tempStep}`;
      errorMessage(msg);
    }

    return returnedDataStatus;
  };

  const validateStepTwo = async (tempFormData, msg, tempStep) => {
    let returnedDataStatus = false;

    switch (tempFormData.aulaChosenLayout) {
      case "uShape":
      case "classroom":
        switch (tempFormData.selectedSeatOption) {
          case "kursiKampus":
            if (
              tempFormData.kursiKampusAmount === null ||
              tempFormData.kursiKampusAmount === "" ||
              tempFormData.kursiKampusAmount === 0 ||
              tempFormData.kursiKampusAmount < 1
            ) {
              msg = `Please fill all fields in Step ${tempStep}`;
              errorMessage(msg);
            } else {
              returnedDataStatus = true;
            }
            break;
          case "kursiStandar":
            if (
              tempFormData.kursiStandardAmount === null ||
              tempFormData.kursiStandardAmount === "" ||
              tempFormData.kursiStandardAmount === 0 ||
              tempFormData.kursiStandardAmount < 1
            ) {
              msg = `Please fill all fields in Step ${tempStep}`;
              errorMessage(msg);
            } else {
              returnedDataStatus = true;
            }
            break;
          default:
            msg = `Please fill all fields in Step ${tempStep}`;
            errorMessage(msg);
            break;
        }
        break;
      case "roundTable":
        if (
          (tempFormData.tableAmount === null || tempFormData.tableAmount === 0) &&
          (tempFormData.chairPerTableAmount === null || tempFormData.chairPerTableAmount === 0)
        ) {
          msg = `Please fill all fields in Step ${tempStep}`;
          errorMessage(msg);
        } else {
          returnedDataStatus = true;
        }
        break;
      case "lesehan":
        if (tempFormData.lesehanArea === null || tempFormData.lesehanArea === "") {
          msg = `Please fill all fields in Step ${tempStep}`;
          errorMessage(msg);
        } else {
          returnedDataStatus = true;
        }
        break;
      case "others":
        if (tempFormData.aulaLayoutDetail === null || tempFormData.aulaLayoutDetail === "") {
          msg = `Please fill all fields in Step ${tempStep}`;
          errorMessage(msg);
        } else {
          returnedDataStatus = true;
        }
        break;

      default:
        msg = `Please fill all fields in Step ${tempStep}`;
        errorMessage(msg);
        break;
    }

    return returnedDataStatus;
  };

  const validateStepThree = async (tempFormData) => {
    let returnedDataStatus = false;

    if (
      tempFormData.flipchartAmount > 5 ||
      tempFormData.whiteboardAmount > 5 ||
      tempFormData.squareTableAmount > 5 ||
      tempFormData.cableRollAmount > 5 ||
      tempFormData.flipchartAmount < 0 ||
      tempFormData.whiteboardAmount < 0 ||
      tempFormData.squareTableAmount < 0 ||
      tempFormData.cableRollAmount < 0
    ) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Error"
            description="Supporting facilities amount exceeds the limit"
            status="error"
          />
        ),
        duration: 3500,
        isClosable: true,
      });
    } else {
      returnedDataStatus = true;
    }

    return returnedDataStatus;
  };

  const prevStep = () => {
    window.scrollTo(0, 0);
    const tempStep = step;
    setStep(tempStep - 1);
  };

  const nextStep = async () => {
    window.scrollTo(0, 0);
    const tempStep = step;
    const tempFormData = formData;
    let msg;

    switch (tempStep) {
      case 1:
        let resultStep1 = await validateStepOne(tempFormData, msg, tempStep);
        if (resultStep1) {
          setStep(tempStep + 1);
        }
        break;
      case 2:
        let resultStep2 = await validateStepTwo(tempFormData, msg, tempStep);
        if (resultStep2) {
          setStep(tempStep + 1);
        }
        break;
      case 3:
        let resultStep3 = await validateStepThree(tempFormData);
        if (resultStep3) {
          setStep(tempStep + 1);
        }
        break;
      default:
        setStep(tempStep + 1);
        break;
    }
  };

  const handleSubmit = () => {
    let tempLoadingState = !isLoading;
    setIsLoading(tempLoadingState);

    let EmptyFields = [];

    for (const property in formData) {
      if (
        !formData[property] &&
        !(
          property === "isWeeklyMeeting" ||
          property === "weeklyMeetingDeadline" ||
          property === "selectedSeatOption" ||
          property === "kursiKampusAmount" ||
          property === "kursiStandardAmount" ||
          property === "tableAmount" ||
          property === "chairPerTableAmount" ||
          property === "lesehanArea" ||
          property === "aulaLayoutDetail" ||
          property === "flipchartAmount" ||
          property === "whiteboardAmount" ||
          property === "squareTableAmount" ||
          property === "cableRollAmount" ||
          property === "additionalInformationAula"
        )
      ) {
        EmptyFields.push(property);
      }
    }

    if (EmptyFields.length > 0) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Booking Failed."
            description={`There are ${EmptyFields.length} empty fields, please fill them first`}
            status="error"
          />
        ),
        duration: 3500,
        isClosable: true,
      });
    } else if (formData["additionalInformationAula"].length >= 255) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Booking Failed."
            description="Aula Additional Information field must be less than 255 characters"
            status="error"
          />
        ),
        duration: 3500,
        isClosable: true,
      });
    } else if (formData["lesehanArea"].length >= 255) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Booking Failed."
            description="Aula Additional Information field must be less than 255 characters"
            status="error"
          />
        ),
        duration: 3500,
        isClosable: true,
      });
    } else if (formData["aulaLayoutDetail"].length >= 255) {
      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Booking Failed."
            description="Aula Additional Information field must be less than 255 characters"
            status="error"
          />
        ),
        duration: 3500,
        isClosable: true,
      });
    } else {
      handleSubmissionData(formData);
    }
  };

  return (
    <>
      <Flex className={styles["header-container"]}>
        <MainHeader
          title="Form Booking Room"
          backLink={step === 1 && `/booking-room/room/${roomData?.id}`}
        />
      </Flex>
      <MultiStepsForm
        params={params}
        onOpen={onOpen}
        roomData={roomData}
        handleInput={handleInput}
        formData={formData}
        startDate={startDate}
        endDate={endDate}
        dateNow={dateNow}
        dateMax={dateMax}
        isWeeklyMeeting={isWeeklyMeeting}
        step={step}
        prevStep={prevStep}
        nextStep={nextStep}
        aulaLayoutType={aulaLayoutType}
        chairType={chairType}
        RoomLayoutHandler={RoomLayoutHandler}
        RadioChangeHandler={RadioChangeHandler}
      />
      <Modal isOpen={isOpen} onClose={onClose} size={"xs"}>
        <ModalOverlay />
        <ModalContent className={styles["lato-font"]} borderRadius="25px">
          <ModalHeader className={styles["text-title-card"]} textAlign="center">
            Booking Confirmation:
            <br />
            {roomData?.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box marginBottom={"1em"}>
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Booking Details
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Subject:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>{formData.booking_purpose}</Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Booked by:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>{formData.user_name}</Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Contact:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>{formData?.user_contact}</Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Attendee:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>{formData?.attendees}</Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Date:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {moment(formData?.booking_at).format("DD MMMM YYYY")}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Time:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {`${formData?.start_usage} - ${formData?.finish_usage}`}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Weekly:</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.isWeeklyMeeting ? "Yes" : "No"}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    {isWeeklyMeeting && (
                      <Flex mb="-0.5em">
                        <Box width={"35%"}>
                          <Flex className={styles["room-detail-info"]}>
                            <Text className={styles["text-variable"]}>Until:</Text>
                          </Flex>
                        </Box>
                        <Box width={"65%"}>
                          <Flex className={styles["room-detail-info"]}>
                            <Text className={styles["text-value"]}>
                              {moment(formData?.weeklyMeetingDeadline).format("DD MMMM YYYY")}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    )}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Aula Request Details
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Layout</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.aulaChosenLayout === "uShape"
                              ? "U Shape"
                              : formData?.aulaChosenLayout === "classroom"
                              ? "Classroom"
                              : formData?.aulaChosenLayout === "roundTable"
                              ? "Round Table"
                              : formData?.aulaChosenLayout === "lesehan"
                              ? "Lesehan"
                              : formData?.aulaChosenLayout === "others"
                              ? "Others"
                              : null}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    {formData?.aulaChosenLayout === "uShape" ||
                    formData?.aulaChosenLayout === "classroom" ? (
                      <>
                        <Flex mb="-0.5em">
                          <Box width={"35%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-variable"]}>Seat Type</Text>
                            </Flex>
                          </Box>
                          <Box width={"65%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-value"]}>
                                {formData?.selectedSeatOption === "kursiKampus"
                                  ? "Kursi Kampus"
                                  : formData?.selectedSeatOption === "kursiStandard"
                                  ? "Kursi Standard"
                                  : null}
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                        <Flex mb="-0.5em">
                          <Box width={"35%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-variable"]}>Seat Amount</Text>
                            </Flex>
                          </Box>
                          <Box width={"65%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-value"]}>
                                {formData?.kursiKampusAmount > 0
                                  ? formData?.kursiKampusAmount
                                  : formData?.kursiStandardAmount}
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </>
                    ) : formData?.aulaChosenLayout === "roundTable" ? (
                      <>
                        <Flex mb="-0.5em">
                          <Box width={"35%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-variable"]}>Table Amount</Text>
                            </Flex>
                          </Box>
                          <Box width={"65%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-value"]}>
                                {formData?.tableAmount || 0} pcs
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                        <Flex mb="-0.5em">
                          <Box width={"35%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-variable"]}>Seats per Table</Text>
                            </Flex>
                          </Box>
                          <Box width={"65%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-value"]}>
                                {formData?.chairPerTableAmount || 0} pcs
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </>
                    ) : formData?.aulaChosenLayout === "lesehan" ? (
                      <>
                        <Flex mb="-0.5em">
                          <Box width={"35%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-variable"]}>Lesehan Area</Text>
                            </Flex>
                          </Box>
                          <Box width={"65%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-value"]}>{formData?.lesehanArea}</Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </>
                    ) : formData?.aulaChosenLayout === "others" ? (
                      <>
                        <Flex mb="-0.5em">
                          <Box width={"35%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-variable"]}>Detailed Layout</Text>
                            </Flex>
                          </Box>
                          <Box width={"65%"}>
                            <Flex className={styles["room-detail-info"]}>
                              <Text className={styles["text-value"]}>
                                {formData?.aulaLayoutDetail}
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </>
                    ) : null}
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Flipcharts</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.flipchartAmount || 0} pcs
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Whiteboards</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.whiteboardAmount || 0} pcs
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Square Tables</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.squareTableAmount || 0} pcs
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Roll Cables</Text>
                        </Flex>
                      </Box>
                      <Box width={"65%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.cableRollAmount || 0} pcs
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex mb="-0.5em">
                      <Box width={"35%"}>
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-variable"]}>Additional Info</Text>
                        </Flex>
                      </Box>
                      <Box
                        width={"65%"}
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        <Flex className={styles["room-detail-info"]}>
                          <Text className={styles["text-value"]}>
                            {formData?.additionalInformationAula}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </ModalBody>
          <ModalFooter>
            {isLoading ? (
              <Button isLoading colorScheme="blue" w="100%">
                Confirm Booking
              </Button>
            ) : (
              <Button onClick={handleSubmit} bgColor="#006F95" color="#fff" w="100%">
                Confirm Booking
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default HallRoomForm;
