import React from "react";

import Header from "../../../../components/SecondaryHeader";
import DriverRequestForm from "../../../../components/BookingCar/MainCar/DriverRequestForm";

function DriverRequestView() {
  return (
    <>
      <Header type="car" title="Daftar Booking Mobil" isPreviousPage={true}/>
      <DriverRequestForm />
    </>
  );
}

export default DriverRequestView;
