import React, { useEffect, useState } from "react";
import { CircularProgress, Flex } from "@chakra-ui/react";
import { FetchDriverRequest } from "../../../../api/fetchDriverRequest";
import Header from "../../../../components/SecondaryHeader";
import BookingForm from "../../../../components/BookingCar/MainCar/BookingForm";
import CarList from "../../../../components/BookingCar/MainCar/CarList";
import NotRegistered from "../../../../components/BookingCar/MainCar/NotRegistered";
import Registered from "../../../../components/BookingCar/MainCar/Registered";
import Inactive from "../../../../components/BookingCar/MainCar/Inactive";
import Contact from "../../../../components/CTA/Contact";
import { useLocation } from "react-router-dom";

function BookingCarView({ bookingType = "Operasional" }) {
  const [carUserStatus, setCarUserStatus] = useState();
  const [isLoadingFetchRequest, setIsLoadingFetchRequest] = useState(true);
  const location = useLocation();

  const getTitleFromPath = () => {
    switch (location.pathname) {
      case "/booking-car":
        return "Operasional";
      case "/booking-car/perjadin":
        return "Perjalanan Dinas";
      case "/booking-car/driver":
        return "Driver";
      default:
        return "";
    }
  };

  localStorage.setItem(
    "bookingFormFilterData",
    JSON.stringify({ booking_type: bookingType })
  );

  useEffect(() => {
    const fetchDriverRequestData = async () => {
      const { driverRequest, loading } = await FetchDriverRequest();
      if (driverRequest.active === false) {
        setCarUserStatus("inactive");
      } else {
        setCarUserStatus(driverRequest.verification_status);
      }
      setIsLoadingFetchRequest(loading);
    };

    fetchDriverRequestData();
  }, []);

  return (
    <Flex
      w="100%"
      bg="white"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Header backLink={"/"} title={getTitleFromPath()} />
      <>
        {isLoadingFetchRequest ? (
          <Flex
            width="100%"
            height="400px"
            justifyContent="center"
            alignItems="center"
            borderBottom="1px solid #EFEFEF"
          >
            <CircularProgress isIndeterminate color="#3182CE" />
          </Flex>
        ) : carUserStatus === "accepted" ? (
          <>
            <BookingForm bookingType={bookingType} isChooseCarFirst={false} />
            <CarList bookingType={bookingType} />
          </>
        ) : carUserStatus === "requested" ? (
          <Registered />
        ) : carUserStatus === "inactive" ? (
          <Inactive />
        ) : (
          <NotRegistered status={carUserStatus} />
        )}
        <Flex
          width="100%"
          direction="column"
          rowGap="24px"
          marginTop="24px"
          marginBottom="48px"
        >
          <Contact type="car" />
        </Flex>
      </>
    </Flex>
  );
}

export default BookingCarView;
