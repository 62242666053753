import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Text, Flex, Box, Button } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

import { FetchUpcoming } from "../../api/fetchUpcoming";
import { CardCarousel, CardSkeleton } from "../card";

function Upcoming({ type = "all" }) {
  const navigate = useHistory();

  const [upcomingData, setUpcomingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const slideSettings = {
    infinite: false,
    arrows: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1.6,
  };

  useEffect(() => {
    const fetchUpcomingData = async ({ type }) => {
      const { upcomingData, loading, error } = await FetchUpcoming({
        type,
        limit: 10,
      });
      setUpcomingData(upcomingData);
      setLoading(loading);
      setError(error);
    };

    if (type === "car") fetchUpcomingData({ type: "car" });
    else fetchUpcomingData({ type: "all" });
  }, [type]);

  if (error) {
    <Box padding="0 16px">
      <Box
        w="100%"
        padding="16px 8px 16px 8px"
        borderRadius="12px"
        bg="#EFEFEF"
      >
        <Text fontSize="14px" fontWeight="400" color="#5E5E5E">
          Error fetching bookings: {error.message}
        </Text>
      </Box>
    </Box>;
  }

  return (
    <Flex w="100%" color="#222222" spacing="0" direction="column" rowGap="8px">
      <Flex w="100%" padding="0 16px" justifyContent="space-between">
        <Text fontSize="14px" fontWeight="600">
          Mendatang
        </Text>
        {!loading && upcomingData.length > 0 && (
          <Button
            variant="link"
            color="#3182CE"
            fontSize="14px"
            fontWeight="400"
            _hover={{ textDecoration: "none" }}
            onClick={() => navigate.push("/upcoming")}
          >
            Lihat Semua
            <ChevronRightIcon size="16px" />
          </Button>
        )}
      </Flex>

      {loading && (
        <Flex w="100%" padding="8px 0" direction="row" columnGap="16px">
          <CardSkeleton isFirstChild={true} />
          <CardSkeleton isLastChild={true} />
        </Flex>
      )}

      {!loading && upcomingData.length === 0 ? (
        <Box padding="0 16px">
          <Box w="100%" padding="8px 0px 8px 0px" borderRadius="12px">
            <Text fontSize="14px" fontWeight="400" color="#7A7A7A">
              Belum ada rencana booking {type === "car" ? "mobil" : ""}
            </Text>
          </Box>
        </Box>
      ) : upcomingData.length === 1 ? (
        <>
          <Box w="calc(100% - 48px)" margin="8px 24px">
            <CardCarousel
              type="upcoming"
              data={upcomingData[0]}
              isSingle={true}
              setUpcomingData={setUpcomingData}
            />
          </Box>
        </>
      ) : (
        <Box
          w="100%"
          sx={{
            ".slick-track": {
              display: "flex",
              flexDirection: "row",
              columnGap: "24px",
              padding: "8px 0",
            },
          }}
        >
          <div className="slider-container">
            <Slider {...slideSettings}>
              {upcomingData.map((data, i) => (
                <CardCarousel
                  type="upcoming"
                  key={i}
                  data={data}
                  setUpcomingData={setUpcomingData}
                />
              ))}
            </Slider>
          </div>
        </Box>
      )}
    </Flex>
  );
}

export default Upcoming;
