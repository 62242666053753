import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import { ACTIVE_BUTTON_COLOR } from "../../../utils/constant";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Session_Availalbe_Car } from "../../../utils/constant";

const FilterSelectCapacity = ({ params }) => {
  const [, setAvailableCarFilter] = useLocalStorage(Session_Availalbe_Car, {});
  const btnRef = React.useRef();
  const btnRefCapacity = React.useRef();
  const {
    isOpen: isOpenCapacity,
    onOpen: onOpenCapacity,
    onClose: onCloseCapacity,
  } = useDisclosure();

  const isDesktop = useIsDesktop();

  const { replace } = useHistory();
  const { pathname } = useLocation();

  const [selectedKapasitas, setSelectedKapasitas] = useState(
    params.capacity || "Semua"
  );
  const SelectCapacity = () => {
    return (
      <RadioGroup
        defaultValue={selectedKapasitas}
        onChange={(e) => setSelectedKapasitas(e)}
      >
        <Stack gap="16px">
          <Text
            as="label"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pr={2}
            fontSize="16px"
          >
            Semua
            <Radio value="Semua"></Radio>
          </Text>
          <Text
            as="label"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pr={2}
            fontSize="16px"
          >
            5 Orang
            <Radio value="5"></Radio>
          </Text>
          <Text
            as="label"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pr={2}
            fontSize="16px"
          >
            7 Orang
            <Radio value="7"></Radio>
          </Text>
        </Stack>
      </RadioGroup>
    );
  };
  const ActionModal = () => {
    return (
      <Flex alignItems="center" gap="16px">
        <Button
          padding="6px 12px"
          borderRadius="100px"
          fontWeight={400}
          fontSize="14px"
          letterSpacing="0.1px"
          color={ACTIVE_BUTTON_COLOR}
          borderColor={ACTIVE_BUTTON_COLOR}
          border="1px"
          variant="outline"
          width="114px"
          height="32px"
          onClick={onCloseCapacity}
        >
          Batal
        </Button>
        <Button
          fontSize="14px"
          bgColor={ACTIVE_BUTTON_COLOR}
          color="white"
          border="1px"
          padding="6px 12px"
          width="114px"
          height="32px"
          borderRadius="100px"
          fontWeight={400}
          onClick={() => {
            const filter = { ...params, capacity: selectedKapasitas };

            setAvailableCarFilter(filter);
            replace({
              pathname,
              search: new URLSearchParams(filter).toString(),
            });

            onCloseCapacity();
          }}
          letterSpacing="0.1px"
        >
          Terapkan
        </Button>
      </Flex>
    );
  };
  return (
    <>
      <Button
        width="fit-content"
        ref={btnRefCapacity}
        color={ACTIVE_BUTTON_COLOR}
        borderColor={ACTIVE_BUTTON_COLOR}
        borderWidth="1px"
        bg="#EBF3FF"
        fontSize={12}
        h="fit-content"
        padding="6px 10px"
        borderRadius="100px"
        fontWeight={400}
        letterSpacing="0.1px"
        alignItems="center"
        onClick={onOpenCapacity}
      >
        Kapasitas: {" "} 
        {params.capacity !== "Semua" && params.capacity ? `${params.capacity} Orang` : "Semua"}
      </Button>

      {isDesktop ? (
        <Modal isOpen={isOpenCapacity} onClose={onCloseCapacity} isCentered>
          <ModalOverlay />
          <ModalContent color="black" zIndex={99} bg="white">
            <ModalHeader>Kapasitas</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SelectCapacity />
            </ModalBody>

            <ModalFooter>
              <ActionModal />
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          isOpen={isOpenCapacity}
          placement="bottom"
          onClose={onCloseCapacity}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            bg="white"
            borderTopRadius="32px"
            maxHeight="90vh"
          >
            <Box 
              width="32px" 
              height="4px" 
              bg="gray.600" 
              borderRadius="100px" 
              margin="16px auto"
            />

            <DrawerBody 
              px="16px" 
              py="0" 
              mt="16px" 
              mb="24px"
            >
              <Text
                fontSize="16px"
                fontWeight="700"
                mb="8px"
              >
                Kapasitas
              </Text>
              <SelectCapacity />
            </DrawerBody>

            <DrawerFooter border="0.5px solid #D6D6D6">
              <ActionModal />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default FilterSelectCapacity;
