import React from "react";
import {Flex} from "@chakra-ui/react";

const CardLobby = ({index, ic, title, body, onClick}) => {
    return (
        <Flex
            key={index}
            onClick={onClick}
            w="100%"
            h="84px"
            bg="#FFFFFF"
            borderRadius="10px"
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
            p="12px"
            mt= {index === 0 ? "20px" : "12px"}
            direction="row"
            gap="12px"
            cursor="pointer">
            <img
                src={ic}
                alt="card-lobby-1"
                style={{
                    width: "48px",
                    height: "auto"
                }}/>
            <Flex
                direction="column"
                justifyContent="center"
                gap="4px">
                <p style={{
                    fontSize:"16px",
                    fontWeight:"600",
                    textAlign:"left" }}>
                    {title}
                </p>
                <p style={{
                    fontSize:"12px",
                    fontWeight:"400",
                    textAlign:"left" }}>
                    {body}
                </p>
            </Flex>
        </Flex>
    )
}

export default CardLobby;