import React from 'react';
import {Button, Flex} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import {MdChevronLeft} from "react-icons/md";

const LobbyHeader = ({leftLink, textLeftLink, rightOnClick, textRightLink}) => {
    const navigate = useHistory();
    return (
        <Flex
            w="100%"
            top={0}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            py = "24px">
            <Button
                bg="white"
                width="77px"
                height="31px"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                color="black"
                fontSize="12px"
                fontWeight="400"
                borderRadius={100}
                onClick={() => navigate.push(leftLink)}
                px = "4px"
                py = "4px"
                pr = "12px">
                <MdChevronLeft size={20} color="#222222"/>
                {textLeftLink}
            </Button>

            <Button
                bg="white"
                width="105px"
                height="31px"
                boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                color="black"
                fontSize="12px"
                fontWeight="400"
                borderRadius={100}
                onClick={rightOnClick}
                px = "10px"
                py = "7px"
            >
                {textRightLink}
            </Button>

        </Flex>

    )
}

export default LobbyHeader;