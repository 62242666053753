import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const LeavingReturningTime = ({ leavingDate, leavingTime, returningDate, returningTime }) => {
  return (
    <Flex direction="row" justifyContent="space-between">
      <Flex w="50%" alignItems="flex-start" direction="column">
        <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
          Pergi
        </Text>
        <Text fontSize="12px" fontWeight="400">
          {leavingDate}
        </Text>
        <Text fontSize="12px" fontWeight="400">
          {leavingTime} WIB
        </Text>
      </Flex>
      <Flex w="50%" alignItems="flex-start" direction="column">
        <Text fontSize="12px" fontWeight="400" color="#5E5E5E" mb="2px">
          Pulang
        </Text>
        <Text fontSize="12px" fontWeight="400">
          {returningDate}
        </Text>
        <Text fontSize="12px" fontWeight="400">
          {returningTime} WIB
        </Text>
      </Flex>
    </Flex>
  );
};

export default LeavingReturningTime;
