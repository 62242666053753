import React from "react";
import { Button, FormLabel, Input, InputGroup, Text, Textarea, useToast } from "@chakra-ui/react";
import { useState } from "react";
import Axios from "../../utils/axiosService";
import CustomToast from "../Toast/CustomToast";

function AddReportForm({ apps, selectedAppId }) {
  const [reportFormValue, setReportFormValue] = useState({
    subject_report: "",
    report_details: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (reportFormValue.subject_report && reportFormValue.report_details) {
      Axios({
        method: "POST",
        url: "/issue-report",
        data: {
          app_id: parseInt(selectedAppId),
          user_id: parseInt(localStorage.getItem("id_user")),
          subject_report: reportFormValue.subject_report,
          report_details: reportFormValue.report_details,
          status: "Report Created",
        },
      })
        .then(() => {
          setIsLoading(false);

          toast({
            position: "top",
            render: () => (
              <CustomToast title="Success!" description="Report Created." status="success" />
            ),
            onCloseComplete: () => {
              window.location.assign("/report");
            },
            duration: 1500,
            isClosable: true,
          });
        })
        .catch((e) => {
          setIsLoading(false);

          toast({
            position: "top",
            render: () => (
              <CustomToast title="Error" description={e.response.data.errors[0]} status="error" />
            ),

            duration: 1500,
            isClosable: true,
          });
        });
    } else {
      setIsLoading(false);

      toast({
        position: "top",
        render: () => (
          <CustomToast
            title="Error"
            description="Subject and details can't be blank!"
            status="error"
          />
        ),

        duration: 1500,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Text as="h2" opacity="0.7" fontWeight="700" fontSize="16px" textAlign="center">
        Form Report {apps.filter((a) => a?.id === selectedAppId)[0]?.name}
      </Text>
      <InputGroup display="block" mt="16px">
        <FormLabel htmlFor="subject">Subject Report</FormLabel>
        <Input
          name="subject_report"
          placeholder="type your subject report"
          value={reportFormValue.subject_report}
          onChange={(e) => {
            e.preventDefault();
            setReportFormValue({ ...reportFormValue, subject_report: e.target.value });
          }}
        />
      </InputGroup>
      <InputGroup display="block" mt="12px">
        <FormLabel htmlFor="details">Report Details</FormLabel>
        <Textarea
          name="report_details"
          placeholder="type report details"
          rows={10}
          value={reportFormValue.report_details}
          onChange={(e) => {
            e.preventDefault();
            setReportFormValue({ ...reportFormValue, report_details: e.target.value });
          }}
        />
      </InputGroup>
      {isLoading ? (
        <Button isLoading type="submit" mt="40px" variant="none" bg="#006F95" color="#FFF" w="100%">
          Submit Report to Admin
        </Button>
      ) : (
        <Button type="submit" mt="40px" variant="none" bg="#006F95" color="#FFF" w="100%">
          Submit Report to Admin
        </Button>
      )}
    </form>
  );
}

export default AddReportForm;
