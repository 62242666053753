import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { FetchCarBookingDetails } from "../../../../api/fetchCarBookingDetails";
import DriverApproved from "../../../../assets/img/bookingCar/driver-approved.png";
import DriverRejected from "../../../../assets/img/bookingCar/driver-rejected.png";
import DriverOnProgress from "../../../../assets/img/bookingCar/upcoming-driver-created.png";
import DriverCancelled from "../../../../assets/img/bookingCar/driver-cancelled.svg";

import Header from "../../../SecondaryHeader";
import LeavingReturningTime from "../../Component/LeavingReturningTime";
import CancelModal from "./Section/CancelModal";

import FillFormModal from "./Section/FillFormModal";

import { formatISOtoDayAndHHmm } from "../../../../utils/formatDate";

import { Box, Button, CircularProgress, Flex, Text } from "@chakra-ui/react";
import CarAttributes from "../../Component/CarAttributes";
import CarLocation from "../../Component/CarLocation";
import RulesSection from "../Summary/Section/Rules";
import DetailDriver from "./Section/DetailDriver";
import UnworthinessDetail from "./Section/UnworthinessDetail";
import Divider from "./Divider";
import { BsWhatsapp } from "react-icons/bs";

const BookingCarDetails = () => {
  const { bookingId } = useParams();
  const location = useLocation();
  const navigate = useHistory();
  const { backLink } = location.state || { backLink: "/" };

  const [headerTitle, setHeaderTitle] = useState("Mendatang");

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isOpenFillFormModal, setIsOpenFillFormModal] = useState(false);

  const [bookingDetails, setBookingDetails] = useState({});
  const [isLoadingBookingDetails, setIsLoadingBookingDetails] = useState(true);

  const [isStartUsagePassed, setIsStartUsagePassed] = useState(false);
  const [isRoadWorthy, setIsRoadWorthy] = useState(true);

  const contactNumber = "+6287709255800";
  const checkRoadWorthiness = (facilityLog) => {
    if (!facilityLog || !Array.isArray(facilityLog)) return true;
    
    const departLog = facilityLog.find(log => log.log_status === "depart");
    if (!departLog) return true;

    return (
      departLog.kaca_spion &&
      departLog.klakson &&
      departLog.lampu &&
      departLog.rem &&
      departLog.dashboard
    );
  };

  useEffect(() => {
    const fetchCarBookingDetails = async () => {
      const { bookingDetails, loading } = await FetchCarBookingDetails({
        id: bookingId,
      });

      const endOfDay = new Date(bookingDetails.start_usage).setHours(
        23,
        59,
        59
      );

      setBookingDetails(bookingDetails);
      setIsLoadingBookingDetails(loading);
      setIsStartUsagePassed(endOfDay <= new Date());
      setIsRoadWorthy(checkRoadWorthiness(bookingDetails?.facility_log));

      if (
        ((bookingDetails.booking_status === "Booking Created" ||
          bookingDetails.booking_status === "In Progress" ||
          bookingDetails.booking_status === "Done") &&
          endOfDay <= new Date()) ||
        bookingDetails.booking_status === "Booking Cancelled"
      ) {
        setHeaderTitle("Riwayat");
      } else if (
        (bookingDetails.booking_status === "Booking Created" ||
          bookingDetails.booking_status === "In Progress" ||
          bookingDetails.booking_status === "Done") &&
        endOfDay >= new Date()
      ) {
        setHeaderTitle("Mendatang");
      }
    };

    fetchCarBookingDetails();
  }, [bookingId]);

  return (
    <>
      <Header type="car" title={headerTitle} backLink={backLink} />
      <Flex direction="column" height="100%">
        {bookingDetails.booking_type !== "Driver" &&
        !isLoadingBookingDetails ? (
          <>
            <Flex
              bg="#F8FBFF"
              padding="24px 0px 0px 0px"
              flexDirection="column"
              rowGap="24px"
              height="100vh"
              marginBottom="64px"
            >
              <Flex rowGap="12px" direction="column" padding="0 16px">
                <Text fontSize="14px" fontWeight="500">
                  Spesifikasi Mobil
                </Text>
                {isLoadingBookingDetails ? (
                  <Flex
                    width="100%"
                    height="calc(100vh - 72px)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress isIndeterminate color="#3182CE" />
                  </Flex>
                ) : (
                  <>
                    <Flex width="100%" direction="column" rowGap="16px">
                      <Flex
                        borderRadius="12px"
                        pt="12px"
                        bg={"white"}
                        boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
                        direction="column"
                      >
                        <Box px="12px">
                          <CarAttributes
                            brand={bookingDetails.car.brand}
                            name={bookingDetails.car.name}
                            car_status={bookingDetails.car.car_status}
                            license_plate={bookingDetails.car.license_plate}
                            capacity={bookingDetails.car.capacity}
                            transmission={bookingDetails.car.transmission}
                            fuel={bookingDetails.car.fuel}
                            color={bookingDetails.car.color}
                            image={bookingDetails.car.image}
                            last_service={bookingDetails.car.last_service}
                          />
                          <Divider />
                          { !isRoadWorthy ? (
                            <>
                              <UnworthinessDetail facilityLog={bookingDetails.facility_log} />
                              <Flex
                                position="fixed"
                                bottom="0"
                                left="0"
                                right="0"
                                bg="white"
                                padding="16px"
                                maxWidth="500px"
                                width="100%"
                                margin="0 auto"
                                boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
                              >
                                <Flex width="100%" justifyContent="space-between" paddingX="16px">
                                  <Button
                                    width="48%"
                                    color="#128C7E"
                                    variant="outline"
                                    fontSize="14px"
                                    fontWeight="500"
                                    borderRadius="100px"
                                    border="1px solid #128C7E"
                                    leftIcon={<BsWhatsapp size={16} />}
                                    _hover={{
                                      borderRadius: "100px",
                                      background: "#B2DFDB",
                                    }}
                                    onClick={() => window.open(`https://wa.me/${contactNumber}`)}
                                  >
                                    Imam Fajri (Admin)
                                  </Button>
                                  <Button
                                    width="48%"
                                    color="white"
                                    fontSize="14px"
                                    fontWeight="400"
                                    borderRadius="100px"
                                    background="#184973"
                                    border="1px solid #184973"
                                    onClick={() => {
                                      if (bookingDetails.booking_type === "Perjadin") {
                                        navigate.push('/booking-car/perjadin');
                                      } else if (bookingDetails.booking_type === "Operasional") {
                                        navigate.push('/booking-car');  
                                      }
                                    }}
                                  >
                                    Booking Mobil Lain
                                  </Button>
                                </Flex>
                              </Flex>
                            </>
                          ) : (
                            <>
                              <CarLocation
                                current_location={
                                  bookingDetails.car.current_location
                                }
                                location_url={bookingDetails.car.location_url}
                              />
                              <Divider />

                              <LeavingReturningTime
                                leavingDate={
                                  formatISOtoDayAndHHmm(bookingDetails.start_usage)
                                    .date
                                }
                                leavingTime={
                                  formatISOtoDayAndHHmm(bookingDetails.start_usage)
                                    .time
                                }
                                returningDate={
                                  formatISOtoDayAndHHmm(bookingDetails.finish_usage)
                                    .date
                                }
                                returningTime={
                                  formatISOtoDayAndHHmm(bookingDetails.finish_usage)
                                    .time
                                }
                              />
                              <Divider />
                              <Flex direction="column" pb="12px" gap="4px">
                                <Text
                                  fontSize="12px"
                                  fontWeight="400"
                                  color="#5E5E5E"
                                >
                                  Keperluan Booking
                                </Text>
                                <Box
                                  width="100%"
                                  height="fit-content"
                                  padding="8px 12px"
                                  gap="10px"
                                  borderRadius="8px"
                                  bg="#F5F5F5"
                                >
                                  <Text
                                    fontSize="12px"
                                    fontWeight="400"
                                    color="#5E5E5E"
                                  >
                                    {bookingDetails.purpose}
                                  </Text>
                                </Box>
                              </Flex>
                            </>
                          )}

                        </Box>
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>

              {bookingDetails.booking_status === "Booking Created" &&
                !isStartUsagePassed && (
                  <Flex direction="column">
                    <RulesSection />
                  </Flex>
                )}
            </Flex>
          </>
        ) : (
          <>
            <DetailDriver
              bookingDetails={bookingDetails}
              bg={"#FFFBE4"}
              image={DriverOnProgress}
              driverStatus="Request Driver Sedang Diproses"
              detailStatus={
                <>
                  Proses dilakukan dalam 3 hari kerja. <br /> Hasil dapat
                  diterima melalui halaman ini
                  <br /> atau melalui kotak masuk email kamu.
                </>
              }
              isShow={bookingDetails.booking_status === "Booking Created"}
            />
            <DetailDriver
              bookingDetails={bookingDetails}
              bg={"#D9F2E3"}
              image={DriverApproved}
              driverStatus="Request Driver Disetujui"
              detailStatus={
                <>
                  Silakan menunggu hingga hari keberangkatan sesuai <br />
                  dengan lokasi yang telah kamu tentukan.
                </>
              }
              isShow={["Approved", "In Progress", "Done"].includes(
                bookingDetails.booking_status
              )}
            />
            <DetailDriver
              bookingDetails={bookingDetails}
              bg={"#FFEDEF"}
              image={DriverRejected}
              driverStatus="Request Driver Ditolak"
              detailStatus={<>Maaf, request kamu telah ditolak Admin.</>}
              contactNumber={contactNumber}
              isShow={bookingDetails.booking_status === "Rejected"}
            />
            <DetailDriver
              bookingDetails={bookingDetails}
              bg={"#F8F9FD"}
              image={DriverCancelled}
              driverStatus="Request Driver Cancelled"
              detailStatus="Request driver telah kamu cancel."
              isShow={bookingDetails.booking_status === "Booking Cancelled"}
            />
          </>
        )}

        {bookingDetails.booking_type === "Driver" &&
          bookingDetails.booking_status !== "Rejected" &&
          bookingDetails.booking_status !== "Booking Cancelled" &&
          !isStartUsagePassed && (
            <Flex
              alignItems="center"
              direction="column"
              width="100%"
              justifyContent="flex-end"
            >
              <Button
                color="red.500"
                variant="none"
                fontSize="12px"
                fontWeight="400"
                mb="24px"
                onClick={() => setIsOpenCancelModal(true)}
              >
                Cancel Booking
              </Button>
            </Flex>
          )}

        {bookingDetails.booking_type === "Driver" && (
          <Flex direction="column" alignItems="center">
            <Text fontSize="12px" fontWeight="400" mb="24px">
              © PT Paragon Technology and Innovation
            </Text>
          </Flex>
        )}

        {bookingDetails.booking_type !== "Driver" &&
          bookingDetails.booking_status === "Booking Created" &&
          !isStartUsagePassed && (
            <Flex
              position="fixed"
              bottom="0"
              left="0"
              right="0"
              bg="white"
              padding="16px"
              maxWidth="500px"
              width="100%"
              margin="0 auto"
              boxShadow="0px -2px 5px rgba(0, 0, 0, 0.1)"
            >
              <Flex width="100%" justifyContent="space-between" paddingX="16px">
                <Button
                  width="48%"
                  color="red.500"
                  variant="ghost"
                  fontSize="14px"
                  fontWeight="500"
                  borderRadius="100px"
                  _hover={{
                    borderRadius: "100px",
                    background: "rgba(255, 0, 0, 0.1)",
                  }}
                  onClick={() => setIsOpenCancelModal(true)}
                >
                  Cancel Booking
                </Button>
                <Button
                  width="48%"
                  color="white"
                  fontSize="14px"
                  fontWeight="400"
                  borderRadius="100px"
                  background="#184973"
                  border="1px solid #184973"
                  onClick={() => setIsOpenFillFormModal(true)}
                >
                  Gunakan Mobil
                </Button>
              </Flex>
            </Flex>
          )}
      </Flex>

      {bookingDetails.booking_status === "In Progress" &&
        bookingDetails.booking_type !== "Driver" &&
        !isStartUsagePassed && (
          <Flex
            borderRadius="0px 0px 12px 12px"
            boxShadow="0px 0px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A"
            bg="#73777F"
            color="white"
            justifyContent="center"
            alignItems="center"
            padding="8px"
            position="fixed"
            bottom="0"
            left="0"
            right="0"
          >
            <Text fontSize="12px" fontWeight="400" textAlign="center">
              Mobil sedang digunakan
            </Text>
          </Flex>
        )}

      <CancelModal
        isOpen={isOpenCancelModal}
        onClose={() => setIsOpenCancelModal(false)}
      />
      <FillFormModal
        isOpen={isOpenFillFormModal}
        onClose={() => setIsOpenFillFormModal(false)}
      />
    </>
  );
};

export default BookingCarDetails;
